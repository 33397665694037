import React from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import PropTypes from 'prop-types';

export const ControlledCollapse = ({
    children,
    isOpened,
    setIsOpened,
    className = '',
    label,
}) => {
    return (
        <div className={`c-collapse ${className}`}>
            <div
                className='c-collapse__label'
                onClick={() => setIsOpened(!isOpened)}
            >
                <div>{label}</div>
                <span>{isOpened ? '-' : '+'}</span>
            </div>
            <ReactCollapse isOpened={isOpened}>{children}</ReactCollapse>
        </div>
    );
};

ControlledCollapse.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isOpened: PropTypes.bool.isRequired,
    setIsOpened: PropTypes.func.isRequired,
};
