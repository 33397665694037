import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/global/layout';
import { SliceZones } from '../components/slices/slice-zones';
import { RegisterForm } from '../components/forms/register/register';
import {AuthProvider} from "../contexts/auth";

const SUMMARY =
    'A global network of the brightest minds at the forefront of science, technology and innovation.';

const Register = ({ data, pageContext }) => {
    if (!data) return null;

    const document = data.prismicJoinAssociates;

    return (
        <AuthProvider>
            <Layout
                pageTitle={document.data.title.text}
                metaTitle={document.data.meta_title.text}
                metaDescription={document.data.meta_description.text || SUMMARY}
                shareImage={document.data.share_image.url}
            >
                <RegisterForm
                    image={document.data.form_image.url}
                    summary={SUMMARY}
                    title={document.data.title.text}
                />
                <SliceZones slices={document.data.body} />
            </Layout>
        </AuthProvider>
    );
};

export const query = graphql`
    query JoinAssociatesQuery {
        prismicJoinAssociates {
            _previewable
            data {
                title {
                    text
                }
                summary {
                    text
                }
                subtitle {
                    text
                }
                form_image {
                    url
                }
                meta_title {
                    text
                }
                meta_description {
                    text
                }
                share_image {
                    url
                }
                body {
                    ... on PrismicJoinAssociatesDataBodyLogos {
                        id
                        items {
                            logos_image {
                                alt
                                copyright
                                url
                                gatsbyImageData
                            }
                            logos_name {
                                text
                                html
                                richText
                            }
                        }
                        primary {
                            logos_title {
                                text
                            }
                            logos_background_image {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyContentWithMedia {
                        id
                        primary {
                            contentmedia_subtitle {
                                text
                            }
                            contentmedia_title {
                                text
                            }
                            contentmedia_content {
                                text
                            }
                            contentmedia_image {
                                url
                            }
                            contentmedia_button_text {
                                text
                            }
                            contentmedia_button_link {
                                url
                            }
                            contentmedia_button2_text {
                                text
                            }
                            contentmedia_button2_link {
                                url
                            }
                            contentmedia_image_alignment
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyRichTextContent {
                        id
                        primary {
                            richtextcontent_content {
                                richText
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyTestimonial {
                        id
                        primary {
                            testimonial_quote {
                                text
                            }
                            testimonial_link_text {
                                text
                            }
                            testimonial_link {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyWhyWorkWithUs {
                        id
                        primary {
                            whyworkwithus_point1 {
                                text
                            }
                            whyworkwithus_point2 {
                                text
                            }
                            whyworkwithus_point3 {
                                text
                            }
                            whyworkwithus_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyUseCases {
                        id
                        items {
                            usecases_usecase {
                                text
                            }
                        }
                        primary {
                            usecases_link_text {
                                text
                            }
                            usecases_title {
                                text
                            }
                            usescases_link {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyTestimonialCarousel {
                        id
                        items {
                            testimonialcarousel_author {
                                text
                            }
                            testimonialcarousel_authorjobtitle {
                                text
                            }
                            testimonialcarousel_image {
                                url
                            }
                            testimonialcarousel_quote {
                                text
                            }
                            testimonialcarousel_title {
                                text
                            }
                        }
                        primary {
                            testimonialcarousel_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyHowItWorksCarousel {
                        id
                        primary {
                            howitworkscarousel_title {
                                text
                            }
                        }
                        items {
                            howitworkscarousel_title {
                                text
                            }
                            howitworkscarousel_summary {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyFeaturedProjects {
                        id
                        primary {
                            featuredprojects_startprojectlink {
                                url
                            }
                            featuredprojects_title {
                                text
                            }
                            display_tag
                        }
                        items {
                            featuredprojects_document {
                                document {
                                    ... on PrismicProject {
                                        id
                                        data {
                                            image {
                                                url
                                            }
                                            summary {
                                                text
                                            }
                                            title {
                                                text
                                            }
                                            industry
                                            service
                                        }
                                        tags
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyServicesCarousel {
                        id
                        items {
                            servicescarousel_image {
                                url
                            }
                            servicescarousel_circleimage {
                                url
                            }
                            servicescarousel_fullscreenimage {
                                url
                            }
                            servicescarousel_link {
                                url
                            }
                            servicescarousel_navigationtitle {
                                text
                            }
                            servicescarousel_summary {
                                text
                            }
                            servicescarousel_title {
                                text
                            }
                        }
                        primary {
                            servicescarousel_subtitle {
                                text
                            }
                            servicescarousel_title {
                                text
                            }
                            servicescarousel_summary {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyFeaturedBlogPosts {
                        id
                        items {
                            featuredblogposts_document {
                                document {
                                    ... on PrismicInsightArticle {
                                        id
                                        data {
                                            listing_image {
                                                url
                                            }
                                            title {
                                                text
                                            }
                                            summary {
                                                text
                                            }
                                        }
                                        url
                                        tags
                                    }
                                    ... on PrismicCustomerStory {
                                        id
                                        data {
                                            listing_image {
                                                url
                                                alt
                                            }
                                            summary {
                                                text
                                            }
                                            title {
                                                text
                                            }
                                        }
                                        url
                                        tags
                                    }
                                }
                                type
                            }
                        }
                        primary {
                            featuredblogposts_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicJoinAssociatesDataBodyListing {
                        id
                        primary {
                            listing_title {
                                text
                            }
                            listing_buttonlink {
                                url
                            }
                            listing_buttontext {
                                text
                            }
                            listing_button2link {
                                url
                            }
                            listing_button2text {
                                text
                            }
                        }
                        items {
                            image {
                                url
                            }
                            listing_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
            }
        }
    }
`;

export default withPrismicPreview(Register);
