/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('body');

export default function AssociatesConsultingAgreement({ showModal, onHide }) {
    return (
        <Modal isOpen={showModal} onRequestClose={onHide}>
            <button type='button' className='btn-close' onClick={onHide}>
                <span aria-hidden='true'>&times;</span>
            </button>

            <div className='AssociatesConsultingAgreement'>
                <table
                    style={{ width: '100%', border: '0' }}
                    cellPadding='0'
                    cellSpacing='0'
                >
                    <tbody>
                        <tr>
                            <td className='AssociatesConsultingAgreement__heading'>
                                CONSULTING AGREEMENT
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px' }}>
                                <strong>BETWEEN:</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (1)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                OUTSMART INSIGHT LTD a company
                                                incorporated in England and
                                                Wales with company number
                                                08334117, whose registered
                                                office is at 124 City Road, London EC1V 2NX (the “
                                                <strong>Company</strong>”); and
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (2)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                You the “
                                                <strong>Consultant</strong>”
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px' }}>
                                <strong>WHEREAS</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (A)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Company operates a platform
                                                for hosting Projects for
                                                Customers and crowdsourcing
                                                information from the Company
                                                {"'"}s knowledge and research
                                                community.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (B)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                By entering into this Agreement,
                                                each Consultant is registered to
                                                be part of the Company’s
                                                knowledge and research
                                                community.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (C)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                As a member of the Company’s
                                                research community, each
                                                Consultant may choose to
                                                contribute to certain knowledge
                                                and insight to the Company for
                                                use within Projects.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (D)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant hereby agrees
                                                that they are either (a) legally
                                                allowed to work in the UK and
                                                have an appropriate visa which
                                                allows the same or, (b) if they
                                                are not on a visa which allows
                                                them to work in the UK, that
                                                they are not carrying out work
                                                for any company in the UK and
                                                will only carry out Services
                                                under the terms of this
                                                Agreement from a location that
                                                is not within the UK.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                width='40'
                                                style={{ padding: '8px' }}
                                            >
                                                (E)
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant hereby agrees
                                                that they are either (a) legally
                                                allowed to work in the UK as an
                                                independent freelance consultant
                                                and have an appropriate visa
                                                where this is necessary which
                                                allows the same or, (b) if they
                                                are not on a visa which allows
                                                them to work in the UK as an
                                                independent freelance
                                                consultant, that they are not
                                                carrying out work for any
                                                company in the UK and will only
                                                carry out Services under the
                                                terms of this Agreement from a
                                                location that is not within the
                                                UK, or (c) if they are carrying
                                                out Services from a location
                                                that is not within the UK as an
                                                independent freelance consultant
                                                that this complies with all
                                                immigration and tax rules, laws
                                                and regulations applicable at
                                                any other locations where they
                                                are carrying out the Service
                                                and, where relevant, their home
                                                country.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '8px' }}>
                                <strong>IT IS AGREED:</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Definitions</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                1.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                In this Agreement, unless the
                                                context otherwise requires:
                                                <br />
                                                {'"'}
                                                <strong>Business Day</strong>
                                                {'"'} means a day (other than a
                                                Saturday or Sunday) on which
                                                banks in the City of London are
                                                open for ordinary banking
                                                business
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    width='100%'
                                                    cellPadding='0'
                                                    cellSpacing='0'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Capacity
                                                                </strong>
                                                                {'"'} means as
                                                                agent,
                                                                consultant,
                                                                contractor,
                                                                subcontractor
                                                                director,
                                                                employee, owner,
                                                                partner,
                                                                shareholder or
                                                                in any other
                                                                capacity;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Commencement
                                                                    Date
                                                                </strong>
                                                                {'"'} means the
                                                                date you
                                                                accepted the
                                                                terms of this
                                                                contract;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                3
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Company
                                                                    Property
                                                                </strong>
                                                                {'"'} means all
                                                                documents,
                                                                books, manuals,
                                                                materials,
                                                                records,
                                                                correspondence,
                                                                papers and
                                                                information (on
                                                                whatever media
                                                                and wherever
                                                                located)
                                                                relating to the
                                                                business or
                                                                affairs of the
                                                                Company or Group
                                                                or their
                                                                customers and
                                                                business
                                                                contacts, and
                                                                any equipment,
                                                                keys, hardware
                                                                or software
                                                                provided for the
                                                                Consultant
                                                                {"'"}s use by
                                                                the Company
                                                                during the
                                                                Engagement, and
                                                                any data or
                                                                documents
                                                                (including
                                                                copies)
                                                                produced,
                                                                maintained or
                                                                stored by the
                                                                Consultant on
                                                                the Company or
                                                                the Consultant
                                                                {"'"}s computer
                                                                systems or other
                                                                electronic
                                                                equipment during
                                                                the Engagement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                4
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Customer
                                                                </strong>
                                                                {'"'} means any
                                                                third party to
                                                                whom the Company
                                                                provides
                                                                services;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                5
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Confidential
                                                                    Information
                                                                </strong>
                                                                {'"'} means (a)
                                                                the provisions
                                                                of this
                                                                Agreement and
                                                                any other
                                                                documents that
                                                                relate to the
                                                                Consultant{"'"}s
                                                                engagement by
                                                                the Company, and
                                                                the process of
                                                                negotiating any
                                                                such
                                                                documentation;
                                                                (b) any
                                                                information
                                                                (including
                                                                without
                                                                limitation
                                                                Customer
                                                                Know-how and
                                                                Project
                                                                Know-how) in
                                                                whatever form
                                                                (including in
                                                                written, oral,
                                                                visual or
                                                                electronic form)
                                                                relating to the
                                                                Company or its
                                                                Group, a
                                                                Customer or its
                                                                Group, the
                                                                Engagement or a
                                                                Project,
                                                                including any
                                                                information that
                                                                is directly or
                                                                indirectly
                                                                disclosed to (or
                                                                otherwise comes
                                                                to the attention
                                                                of or becomes
                                                                known to) the
                                                                Consultant as a
                                                                result of this
                                                                Agreement, the
                                                                Consultant’s
                                                                involvement in a
                                                                Project or the
                                                                Consultant’s
                                                                provision of the
                                                                Services, and
                                                                (c) all
                                                                documents that
                                                                contain or
                                                                reflect or are
                                                                generated from
                                                                any of the
                                                                foregoing and
                                                                all copies of
                                                                any of the
                                                                foregoing. For
                                                                the avoidance of
                                                                doubt, in this
                                                                Agreement
                                                                {'"'}
                                                                Confidential
                                                                Information{
                                                                    '"'
                                                                }{' '}
                                                                shall not
                                                                include
                                                                information that
                                                                is in or becomes
                                                                part of the
                                                                public domain
                                                                otherwise than
                                                                as a result of
                                                                disclosure by
                                                                the Consultant
                                                                in breach of
                                                                this Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                6
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Customer
                                                                    Know-how
                                                                </strong>
                                                                {'"'} means all
                                                                Know-how which
                                                                is the property
                                                                of the Customer;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                7
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Engagement
                                                                </strong>
                                                                {'"'} means the
                                                                engagement of
                                                                the Consultant
                                                                by the Company
                                                                on the terms of
                                                                this Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                8
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Group
                                                                </strong>
                                                                {'"'} means in
                                                                relation to any
                                                                company and any
                                                                other company
                                                                which at the
                                                                time is that
                                                                Company{"'"}s
                                                                holding company
                                                                or subsidiary,
                                                                or the
                                                                subsidiary of
                                                                any such holding
                                                                company and,
                                                                {'"'}
                                                                <strong>
                                                                    Member
                                                                </strong>
                                                                {'"'} of a Group
                                                                has a
                                                                corresponding
                                                                meaning;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                9
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    HITs
                                                                </strong>
                                                                {'"'} has the
                                                                meaning given in
                                                                clause 4.2.1;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                10
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Intellectual
                                                                    Property
                                                                </strong>
                                                                {'"'} means all
                                                                rights, title
                                                                and interest (as
                                                                such rights,
                                                                title and
                                                                interests are
                                                                subsisting
                                                                anywhere in the
                                                                world) to and in
                                                                any intellectual
                                                                property,
                                                                including (but
                                                                not limited to)
                                                                patents, utility
                                                                models, trade
                                                                marks, service
                                                                marks, trade
                                                                names, internet
                                                                domain names,
                                                                URLs, rights in
                                                                designs,
                                                                copyrights
                                                                (including
                                                                rights in
                                                                computer
                                                                software), moral
                                                                rights,
                                                                contractual
                                                                waivers of moral
                                                                rights,
                                                                topography
                                                                rights, rights
                                                                in databases,
                                                                logos, getup,
                                                                trade secrets,
                                                                plant variety
                                                                rights,
                                                                confidential
                                                                information,
                                                                Know-how, rights
                                                                protecting
                                                                goodwill and
                                                                reputation and,
                                                                Project
                                                                Know-how, in all
                                                                cases whether
                                                                registered,
                                                                unregistered or
                                                                registrable and
                                                                including
                                                                registrations
                                                                and applications
                                                                for registration
                                                                of any of these
                                                                and rights to
                                                                apply for the
                                                                same and all
                                                                rights and forms
                                                                of protection of
                                                                a similar nature
                                                                or having
                                                                equivalent or
                                                                similar effect
                                                                to any of the
                                                                foregoing
                                                                anywhere in the
                                                                world and
                                                                applications for
                                                                or registrations
                                                                of any of the
                                                                foregoing;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                11
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Know-how
                                                                </strong>
                                                                {'"'} means any
                                                                discovery,
                                                                development,
                                                                technical
                                                                information,
                                                                data, knowledge,
                                                                techniques,
                                                                processes,
                                                                market
                                                                information,
                                                                lists of
                                                                customers, lists
                                                                of suppliers,
                                                                systems,
                                                                formulae,
                                                                designs,
                                                                computer
                                                                programs,
                                                                records,
                                                                relevant
                                                                material,
                                                                patentable or
                                                                non-patentable
                                                                invention, idea,
                                                                method,
                                                                technical or
                                                                commercial
                                                                information and
                                                                any other
                                                                know-how,
                                                                database,
                                                                drawing,
                                                                literary work,
                                                                product,
                                                                packaging,
                                                                (including,
                                                                without
                                                                limitation any
                                                                Intellectual
                                                                Property) in any
                                                                form whatsoever
                                                                including
                                                                without
                                                                limitation
                                                                documents,
                                                                books, manuals,
                                                                materials,
                                                                records,
                                                                correspondence,
                                                                papers and
                                                                information (on
                                                                whatever media
                                                                and wherever
                                                                located) and any
                                                                equipment, keys,
                                                                hardware or
                                                                software
                                                                provided for the
                                                                Consultant
                                                                {"'"}s use, and
                                                                any data or
                                                                documents
                                                                (including
                                                                copies)
                                                                produced,
                                                                maintained or
                                                                stored by the
                                                                Consultant on
                                                                any computer
                                                                systems or other
                                                                electronic
                                                                equipment;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Law
                                                                </strong>
                                                                {'"'} means all
                                                                applicable
                                                                legislation,
                                                                statutes,
                                                                directives,
                                                                regulations,
                                                                judgments,
                                                                decisions,
                                                                decrees, orders,
                                                                instruments,
                                                                by-laws, and
                                                                other
                                                                legislative
                                                                measures or
                                                                decisions having
                                                                the force of
                                                                law, treaties,
                                                                conventions and
                                                                other agreements
                                                                between states,
                                                                or between
                                                                states and the
                                                                European Union
                                                                or other
                                                                supranational
                                                                bodies, rules of
                                                                common law,
                                                                customary law
                                                                and equity and
                                                                all civil or
                                                                other codes and
                                                                all other laws
                                                                of, or having
                                                                effect in, any
                                                                jurisdiction
                                                                from time to
                                                                time;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                13
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Project
                                                                </strong>
                                                                {'"'} means any
                                                                project, work,
                                                                instruction or
                                                                engagement for
                                                                which the
                                                                Company{"'"}s
                                                                services are
                                                                required by the
                                                                Customer
                                                                pursuant to this
                                                                Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                14
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Project
                                                                    Know-how
                                                                </strong>
                                                                {'"'} means all
                                                                Know-how which
                                                                is made,
                                                                developed or
                                                                otherwise
                                                                acquired by the
                                                                Customer, the
                                                                Company, any
                                                                other Project
                                                                Participant
                                                                and/or the
                                                                Consultant in
                                                                the course of
                                                                and for the
                                                                purpose of
                                                                carrying on a
                                                                particular
                                                                Project,
                                                                including
                                                                without
                                                                limitation any
                                                                Intellectual
                                                                Property, or any
                                                                other work that
                                                                is made,
                                                                devised,
                                                                created,
                                                                improved or
                                                                developed by the
                                                                Company, the
                                                                Consultant, the
                                                                Customer or any
                                                                Project
                                                                Participant
                                                                during the
                                                                period of
                                                                Consultant’s
                                                                involvement with
                                                                a Project and
                                                                the Consultant’s
                                                                provision of
                                                                services under
                                                                this Agreement,
                                                                including
                                                                without
                                                                limitation where
                                                                such work is of
                                                                value to, or is
                                                                capable of being
                                                                commercialised
                                                                or used by, or
                                                                otherwise used
                                                                in, the business
                                                                of the Company
                                                                or the Customer
                                                                or their
                                                                respective
                                                                Groups;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                15
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Project
                                                                    Participant
                                                                </strong>
                                                                {'"'} means any
                                                                other consultant
                                                                whose services
                                                                are engaged by
                                                                the Company to
                                                                carry out a
                                                                particular
                                                                Project;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                16
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Project
                                                                    Rights
                                                                </strong>
                                                                {'"'} means any
                                                                Intellectual
                                                                Property rights
                                                                or Project
                                                                Know-how;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                17
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Services
                                                                </strong>
                                                                {'"'} means the
                                                                services
                                                                provided by the
                                                                Consultant
                                                                pursuant to the
                                                                terms of this
                                                                Agreement or any
                                                                Project Brief;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                18
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Substitute
                                                                </strong>
                                                                {'"'} means a
                                                                substitute
                                                                engaged by the
                                                                Consultant under
                                                                the terms of
                                                                this Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                19
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Termination
                                                                    Date
                                                                </strong>
                                                                {'"'} the date
                                                                on which this
                                                                Agreement is
                                                                terminated in
                                                                accordance with
                                                                its terms; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                20
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}
                                                                <strong>
                                                                    Working
                                                                    Hours
                                                                </strong>
                                                                {'"'} means 9:30
                                                                am to 5:30 pm on
                                                                a Business Day.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                1.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Clause headings and sub-headings
                                                in this Agreement are inserted
                                                for convenience only and shall
                                                not affect the interpretation of
                                                this Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                1.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                References to this Agreement
                                                include this Agreement as
                                                amended or varied in accordance
                                                with its terms.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                1.4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                In this Agreement, unless the
                                                context otherwise requires:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    width='100%'
                                                    cellPadding='0'
                                                    cellSpacing='0'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (a)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to a
                                                                “
                                                                <strong>
                                                                    person
                                                                </strong>
                                                                ” or “
                                                                <strong>
                                                                    third party
                                                                </strong>
                                                                ” includes any
                                                                individual,
                                                                company,
                                                                corporation sole
                                                                or aggregate,
                                                                firm,
                                                                partnership,
                                                                body corporate,
                                                                joint venture,
                                                                state or agency
                                                                of a state,
                                                                association,
                                                                organisation,
                                                                institution,
                                                                trust, agency,
                                                                and any
                                                                unincorporated
                                                                association, in
                                                                each case
                                                                whether or not
                                                                having a
                                                                separate legal
                                                                personality;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (b)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                one gender
                                                                includes any
                                                                other gender;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (c)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                the singular
                                                                includes the
                                                                plural and vice
                                                                versa;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (d)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                {'"'}holding
                                                                company
                                                                {'"'} and {'"'}
                                                                subsidiary{
                                                                    '"'
                                                                }{' '}
                                                                mean
                                                                {'"'}holding
                                                                company
                                                                {'"'} and {'"'}
                                                                subsidiary{
                                                                    '"'
                                                                }{' '}
                                                                respectively as
                                                                defined in
                                                                section 1159 of
                                                                the Companies
                                                                Act 2006 and
                                                                {'"'}subsidiary
                                                                undertaking{'"'}{' '}
                                                                means {'"'}
                                                                subsidiary
                                                                undertaking{
                                                                    '"'
                                                                }{' '}
                                                                as defined in
                                                                section 1162 of
                                                                the Companies
                                                                Act 2006;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (e)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                every reference
                                                                to a particular
                                                                Law shall be
                                                                construed also
                                                                as a reference
                                                                to all other
                                                                Laws made under
                                                                the Law referred
                                                                to and to all
                                                                such Laws as
                                                                amended,
                                                                re-enacted,
                                                                consolidated or
                                                                replaced or as
                                                                their
                                                                application or
                                                                interpretation
                                                                is affected by
                                                                other Laws from
                                                                time to time and
                                                                whether before
                                                                or after
                                                                Completion
                                                                provided that,
                                                                as between the
                                                                parties, no such
                                                                amendment or
                                                                modification
                                                                shall apply for
                                                                the purposes of
                                                                this Agreement
                                                                to the extent
                                                                that it would
                                                                impose any new
                                                                or extended
                                                                obligation,
                                                                liability or
                                                                restriction on,
                                                                or otherwise
                                                                adversely affect
                                                                the rights of,
                                                                any party;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <br />
                                                            </td>
                                                            <td>
                                                                <br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (f)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                clauses are
                                                                references to
                                                                clauses of this
                                                                Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (g)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to a{' '}
                                                                {'"'}party{'"'}{' '}
                                                                means a party to
                                                                this Agreement
                                                                and includes its
                                                                successors in
                                                                title, personal
                                                                representatives
                                                                and permitted
                                                                assigns;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (h)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to a{' '}
                                                                {'"'}company
                                                                {'"'} includes
                                                                any company,
                                                                corporation or
                                                                other body
                                                                corporate
                                                                wherever and
                                                                however
                                                                incorporated or
                                                                established;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (i)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to{' '}
                                                                {'"'}
                                                                sterling{
                                                                    '"'
                                                                }. {'"'}
                                                                pounds sterling
                                                                {'"'} or
                                                                {'"'}£{'"'} are
                                                                references to
                                                                the lawful
                                                                currency from
                                                                time to time of
                                                                the United
                                                                Kingdom;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (j)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                times of the day
                                                                are to London
                                                                time unless
                                                                otherwise
                                                                stated;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (k)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                writing shall
                                                                include any
                                                                modes of
                                                                reproducing
                                                                words in a
                                                                legible and
                                                                non-transitory
                                                                form;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (l)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                references to
                                                                any English
                                                                legal term for
                                                                any action,
                                                                remedy, method
                                                                of judicial
                                                                proceeding,
                                                                legal document,
                                                                legal status,
                                                                court official
                                                                or any other
                                                                legal concept or
                                                                thing shall in
                                                                respect of any
                                                                jurisdiction
                                                                other than
                                                                England be
                                                                deemed to
                                                                include what
                                                                most nearly
                                                                approximates in
                                                                that
                                                                jurisdiction to
                                                                the English
                                                                legal term;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (m)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                words introduced
                                                                by the word{' '}
                                                                {'"'}other
                                                                {'"'} shall not
                                                                be given a
                                                                restricted
                                                                meaning because
                                                                they are
                                                                preceded by
                                                                words referring
                                                                to a particular
                                                                class of acts,
                                                                matters or
                                                                things; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (n)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                general words
                                                                shall not be
                                                                given a
                                                                restrictive
                                                                meaning because
                                                                they are
                                                                followed by
                                                                words which are
                                                                particular
                                                                examples of the
                                                                acts, matters or
                                                                things covered
                                                                by the general
                                                                words and the
                                                                words {'"'}
                                                                includes{
                                                                    '"'
                                                                }{' '}
                                                                and {'"'}
                                                                including
                                                                {'"'} shall be
                                                                construed
                                                                without
                                                                limitation
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Term of Engagement
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                2.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Subject to clause 12, the
                                                Engagement shall commence on the
                                                Commencement Date and shall
                                                continue unless and until
                                                terminated as provided by the
                                                terms of this Agreement by
                                                either party giving one month
                                                {"'"}s notice.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                2.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Company shall engage the
                                                Consultant and the Consultant
                                                shall provide the Services in
                                                accordance with the terms set
                                                out in this Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Status</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                3.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The relationship of the
                                                Consultant to the Company will
                                                be that of independent
                                                contractor and nothing in this
                                                Agreement shall render the
                                                Consultant an employee, worker,
                                                agent or partner of the Company
                                                and the Consultant shall not
                                                hold themselves out as such.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Obligations of Company to
                                                    Consultant
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Company shall be under no
                                                obligation to provide work to
                                                the Consultant or to use the
                                                Consultant
                                                {"'"}s services and the
                                                Consultant shall be under no
                                                obligation to accept an offer to
                                                provide the Consultant{"'"}s
                                                services with reference to a
                                                particular Project.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Upon the identification of a
                                                Project for which the Consultant
                                                may have the requisite skills
                                                and experience, the Company
                                                shall confirm the terms which
                                                apply to the Consultant{"'"}s
                                                provision of Services (the
                                                {'"'}
                                                <strong>Project Brief</strong>
                                                {'"'}). This shall include as a
                                                minimum:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40' />
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.2.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the scope of the
                                                                Services
                                                                required to be
                                                                provided by the
                                                                Consultant to
                                                                the Company. The
                                                                scope of the
                                                                Services may be
                                                                broken down into
                                                                individual tasks
                                                                ({'"'}
                                                                <strong>
                                                                    HITs
                                                                </strong>
                                                                {'"'}) to be
                                                                undertaken by
                                                                the Consultant
                                                                either alone or
                                                                in conjunction
                                                                with other
                                                                Project
                                                                Participants;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.2.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the fees payable
                                                                to the
                                                                Consultant in
                                                                respect of each
                                                                HIT;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.2.3
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                any applicable
                                                                timescales for
                                                                the delivery of
                                                                the Services and
                                                                each HIT; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.2.4
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                any key
                                                                performance
                                                                indicators or
                                                                performance
                                                                objectives which
                                                                apply to the
                                                                provision of the
                                                                Services and
                                                                completion of
                                                                each HIT.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Upon the completion of each HIT
                                                by the Consultant, the Company
                                                shall confirm whether the
                                                quality and scope of the work
                                                complies with the Company{"'"}s
                                                requirements and whether the
                                                Consultant has met any
                                                prescribed key performance
                                                indicators or performance
                                                objectives, in each case as set
                                                out in the Project Brief. This
                                                shall be determined by the
                                                Company at its absolute
                                                discretion.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                If the Company determines that
                                                the Consultant has not met its
                                                requirements, as set out in the
                                                Project Brief, the Company may
                                                (at its absolute discretion) set
                                                out the improvements required
                                                and give the Consultant a
                                                further specified timeframe to
                                                achieve such improvements.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.5
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                No fee shall be payable to the
                                                Consultant where:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40' />
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.5.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the Consultant
                                                                fails to deliver
                                                                a HIT or the
                                                                Services in
                                                                accordance with
                                                                the requirements
                                                                of the Project
                                                                Brief; or
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                4.5.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the work
                                                                produced by the
                                                                Consultant in
                                                                respect of any
                                                                HIT or the
                                                                Services is not
                                                                incorporated
                                                                within the
                                                                Company
                                                                {"'"}s work
                                                                product which is
                                                                provided to a
                                                                Customer.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.6
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Company shall only reimburse
                                                the Consultant for expenses on
                                                terms agreed to in writing by
                                                the Company acting in its sole
                                                discretion prior to the
                                                commencement of the Services and
                                                where incurred wholly in
                                                relation to a specific Project
                                                as soon as reasonably possible
                                                after full disclosure of such
                                                expenses to the Company
                                                (including providing valid
                                                receipts to <br />
                                                <br />
                                                the Company where appropriate).
                                                The Company will not be obliged
                                                to reimburse expenses which have
                                                not been submitted within one
                                                month of being incurred.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                4.7
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant will be paid all
                                                fees and expenses due to him as
                                                expressly agreed with the
                                                Company in writing and in
                                                accordance with the terms of
                                                this Agreement and the Project
                                                Brief (if any) within 30 days of
                                                the conclusion of a Project.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Obligations of Consultant to
                                                    Company
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant may be considered
                                                for and where appropriate
                                                invited to participate in
                                                Projects which are suitable to
                                                the Consultant{"'"}s skills,
                                                experience and past performance
                                                on Projects to be determined by
                                                the Company from time to time.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant shall provide the
                                                Services with all due care,
                                                skill and ability and use the
                                                Consultant
                                                {"'"}s best endeavours to
                                                promote the interests of the
                                                Company.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant shall promptly
                                                give to the Company all
                                                information and reports as it
                                                may reasonably require in
                                                connection with the provision of
                                                the Services.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5.4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant agrees to
                                                indemnify the Company and keep
                                                it indemnified at all times
                                                against all or any costs,
                                                claims, damages or expenses
                                                incurred by the Company, or for
                                                which the Customer may become
                                                liable, with respect to any
                                                Intellectual Property
                                                infringement claim or other
                                                claim relating to the services
                                                supplied by the Consultant to
                                                the Company during the course of
                                                the agreement. The Company may
                                                at its option satisfy this
                                                indemnity (in whole or in part)
                                                by way of deduction from any
                                                payments due to the Consultant.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                5.5
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant may, with the
                                                prior written approval of the
                                                Company and subject to the
                                                following proviso, appoint a
                                                suitably qualified and skilled
                                                Substitute to perform the
                                                Services on the Consultant{"'"}s
                                                behalf, provided that the
                                                Substitute shall be required to
                                                enter into direct undertakings
                                                the Consultant has obtained the
                                                Substitute’s express agreement
                                                to be bound by the obligations
                                                of this Agreement as if the
                                                Substitute is the Consultant
                                                with the Company, including with
                                                regard to confidentiality and
                                                Intellectual Property. If the
                                                Company accepts the Substitute,
                                                the Consultant shall continue to
                                                invoice the Company in
                                                accordance with this Agreement
                                                and shall be responsible for the
                                                remuneration of the Substitute.
                                                For the avoidance of doubt, the
                                                Consultant will continue to be
                                                subject to all duties and
                                                obligations under this Agreement
                                                for the duration of the
                                                appointment of the Substitute.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                6
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Publication and Publicity
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                6.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Subject to clause 6.2, the
                                                Consultant shall at all times
                                                whether during or after the
                                                period of this Agreement:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellPadding='0'
                                                    cellSpacing='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                a
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                Keep and treat
                                                                as strictly
                                                                confidential all
                                                                Confidential
                                                                Information and
                                                                Project
                                                                Know-how; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                b
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                make no use
                                                                whatsoever of
                                                                Confidential
                                                                Information or
                                                                Project Know-how
                                                                for the
                                                                Consultant{"'"}s
                                                                own advantage or
                                                                for the
                                                                advantage of a
                                                                third party, or
                                                                otherwise for
                                                                any purpose
                                                                outside of this
                                                                scope of the
                                                                scope of the
                                                                applicable
                                                                Project Brief or
                                                                the terms of
                                                                this Agreement.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                6.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant shall not,
                                                without prior written consent of
                                                the Company, make any
                                                publication, or submission for
                                                publication, relating to the
                                                Project or Project Know-how or
                                                of any person{"'"}s findings in
                                                relation thereto. The Consultant
                                                acknowledges that consent may at
                                                the absolute discretion of the
                                                Company be withheld. The
                                                Consultant shall give eight
                                                weeks prior written notice to
                                                the Company of the intended date
                                                of any such publication together
                                                with a copy of any relevant
                                                material. This restriction on
                                                disclosure shall not apply to
                                                any information which is already
                                                in the public domain otherwise
                                                than by the Consultant
                                                {"'"}s breach of their
                                                obligations under this Agreement
                                                or that is required to be
                                                disclosed by Law in which case
                                                only that portion of it is
                                                disclosed and the official
                                                authority requiring the
                                                disclosure shall be informed
                                                about and (to the greatest
                                                extent possible) subject to the
                                                conditions in this Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                6.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                For the purposes of clause 6.2,
                                                “publication” shall include any
                                                means (whether written,
                                                electronic or oral) by which
                                                information relating to the
                                                Project or Project Know-how is
                                                made available to the public,
                                                including, without limitation,
                                                the giving of any discourse and
                                                distribution or display of any
                                                written material relating
                                                thereto; and, for these purposes
                                                “public” shall include any
                                                section of the public.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                7
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Other Activities
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                7.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Nothing in this Agreement shall
                                                prevent the Consultant from
                                                being engaged, concerned or
                                                having any financial interest in
                                                any Capacity in any other
                                                business, trade, profession or
                                                occupation during the term of
                                                the Engagement provided that:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                7.1.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                such activity
                                                                does not cause a
                                                                breach of any of
                                                                the Consultant
                                                                {"'"}s
                                                                obligations
                                                                under this
                                                                Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                7.1.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the Consultant
                                                                shall not engage
                                                                in any such
                                                                activity if it
                                                                relates to a
                                                                business which
                                                                is similar to or
                                                                in any way
                                                                competitive with
                                                                the business of
                                                                the Company
                                                                without the
                                                                prior written
                                                                consent of the
                                                                Company, which
                                                                may be withheld
                                                                at the Company
                                                                {"'"}s absolute
                                                                discretion; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                7.1.3
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the Consultant
                                                                shall give
                                                                priority to the
                                                                provision of the
                                                                Services to the
                                                                Company over any
                                                                other business
                                                                activities
                                                                undertaken by
                                                                the Consultant
                                                                during the
                                                                course of the
                                                                Engagement.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                7.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant shall not,
                                                without the prior written
                                                consent of the Company, which it
                                                will not unreasonably withhold,
                                                work in any capacity for the
                                                Customer within six months of
                                                the expiry of an Agreement with
                                                the said Customer for work in
                                                which the Consultant has been a
                                                participant, otherwise than
                                                pursuant to a contract with the
                                                Company, except where prior to
                                                signing this Agreement the
                                                Customer has used the services
                                                of the Consultant otherwise than
                                                pursuant to the consent of the
                                                Company or pursuant to a
                                                contract between the Company and
                                                the Customer.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </td>
                                            <td>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                8
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Confidentiality</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <br />
                                            </td>
                                            <td>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                8.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant acknowledges that
                                                in the course of the Engagement
                                                the Consultant will have access
                                                to Confidential Information. The
                                                Consultant has therefore agreed
                                                to accept the restrictions in
                                                this clause.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                8.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant agrees and
                                                undertakes to the Company that
                                                the Consultant will hold the
                                                Confidential Information in
                                                strict confidence and will not
                                                disclose, reproduce or
                                                distribute any Confidential
                                                Information at any time, in
                                                whole or in part, directly or
                                                indirectly to any persons,
                                                except where such disclosure,
                                                reproduction or distribution is:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (a)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                for the purpose
                                                                of directly
                                                                contributing to
                                                                the particular
                                                                Project for
                                                                which the
                                                                relevant
                                                                Confidential
                                                                Information was
                                                                disclosed to the
                                                                Consultant and
                                                                such disclosure
                                                                is to the
                                                                Company or its
                                                                directors,
                                                                officers,
                                                                employees,
                                                                agents,
                                                                consultants,
                                                                Project
                                                                Participants and
                                                                advisers as
                                                                required by the
                                                                same for the
                                                                purpose of
                                                                contributing to
                                                                the particular
                                                                Project for
                                                                which the
                                                                relevant
                                                                Confidential
                                                                Information was
                                                                disclosed to the
                                                                Consultant;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (b)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                required by any
                                                                Law or
                                                                regulation of
                                                                any country with
                                                                jurisdiction
                                                                over the affairs
                                                                of the Company
                                                                or its Group; or
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (c)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                required by any
                                                                stock exchange
                                                                or competent
                                                                governmental or
                                                                regulatory
                                                                authority or any
                                                                order of any
                                                                court of
                                                                competent
                                                                jurisdiction.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                8.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant further agrees
                                                not to use Confidential
                                                Information for the Consultant
                                                {"'"}s own purposes or for any
                                                purposes other than those of the
                                                Company and/or the Customer and
                                                not to cause (whether directly,
                                                indirectly or through any
                                                failure to exercise due care and
                                                diligence) any unauthorised
                                                disclosure of any Confidential
                                                Information.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                8.4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Upon a request from the Company
                                                at any time, the Consultant will
                                                immediately return to the
                                                Company all Customer Know-how,
                                                Project Know-how, documents
                                                evidencing Project Rights,
                                                Confidential Information or any
                                                other documents (in whatever
                                                form) provided during the course
                                                of the Engagement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Intellectual Property
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Unless agreed otherwise in
                                                writing in advance by a director
                                                of the Company, no Project
                                                Know-how or Project Rights
                                                (including any right to apply
                                                for protection of Project
                                                Know-how or Project Rights in
                                                any part of the world) shall
                                                belong to the Consultant. The
                                                Consultant hereby assigns to the
                                                Company all existing and future
                                                Project Rights and all materials
                                                embodying the Project Rights to
                                                the fullest extent permitted by
                                                Law. The Consultant shall render
                                                all assistance and do all acts
                                                and things as may, in the
                                                opinion of the Company (acting
                                                in its sole discretion), be
                                                necessary or desirable to enable
                                                the Company to apply for
                                                protection of itself or become
                                                recognised as proprietor of such
                                                Project Rights.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant acknowledges that
                                                in the course of providing the
                                                Services to the Company and in
                                                the course of the Consultant’s
                                                involvement in a Project, the
                                                Consultant may create Project
                                                Know-how or Project Rights. The
                                                Consultant warrants to the
                                                Company:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (a)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                that the
                                                                Consultant will
                                                                notify the
                                                                Company in
                                                                writing of all
                                                                Project Know-how
                                                                and Project
                                                                Rights promptly
                                                                upon its
                                                                creation and
                                                                keep the same
                                                                confidential;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (b)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                not to register
                                                                nor attempt to
                                                                register any
                                                                Project Know-how
                                                                and Project
                                                                Rights unless
                                                                requested to do
                                                                so by the
                                                                Company;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (c)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                that to the
                                                                extent that
                                                                Project Rights
                                                                and Project
                                                                Know-how is
                                                                created jointly
                                                                by the
                                                                Consultant with
                                                                another third
                                                                party (except
                                                                for other
                                                                Project
                                                                Participants,
                                                                the Company and
                                                                the Customer of
                                                                the relevant
                                                                Project) the
                                                                Consultant has
                                                                obtained (or, in
                                                                respect of
                                                                future works
                                                                shall promptly
                                                                obtain) a full
                                                                assignment of
                                                                the Intellectual
                                                                Property in such
                                                                Project Know-how
                                                                in favour of the
                                                                Company or its
                                                                nominee; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (d)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the Consultant
                                                                has not given,
                                                                and will not
                                                                give, permission
                                                                to any third
                                                                party to use any
                                                                of the Project
                                                                Rights or
                                                                Project Know-how
                                                                created by the
                                                                Consultant
                                                                during or
                                                                otherwise
                                                                directly or
                                                                indirectly for
                                                                the purposes of
                                                                or related to a
                                                                Project, except
                                                                as permitted
                                                                under this
                                                                Agreement.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                To the extent that under
                                                applicable Law, any assignment
                                                of Intellectual Property in the
                                                Project Know-how and Project
                                                Rights by the Consultant is not
                                                effective immediately (or
                                                immediately upon creation of a
                                                Project Know-how or any Project
                                                Rights by the Consultant) the
                                                Consultant holds legal title in
                                                these rights on trust for the
                                                Company and agrees to assign
                                                such Project Know-how and
                                                Project Rights as directed by
                                                the Company immediately upon
                                                request of the Company after its
                                                creation.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.4
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant shall not do
                                                anything to devalue, compromise
                                                or reduce the Project Rights or
                                                to challenge the Company’s
                                                ownership thereof. The
                                                Consultant shall co-operate
                                                fully with the Company to enable
                                                and assist it to apply for,
                                                register, maintain, prosecute,
                                                defend and enforce any Project
                                                Rights, including doing the
                                                following immediately upon
                                                request by the Company (whether
                                                before or after the termination
                                                of this Agreement and without
                                                limitation in time):
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (a)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                apply or join
                                                                with the Company
                                                                to apply for
                                                                registrations of
                                                                any Project
                                                                Rights or other
                                                                protection or
                                                                registration in
                                                                the United
                                                                Kingdom,
                                                                European Union,
                                                                United States of
                                                                America and in
                                                                any other part
                                                                of the world
                                                                for, or in
                                                                relation to, any
                                                                relevant results
                                                                or proceeds of
                                                                the Services
                                                                provided by the
                                                                Consultant under
                                                                this Agreement;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (b)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                execute all
                                                                instruments and
                                                                do all things
                                                                necessary for
                                                                vesting all
                                                                Project Rights
                                                                or protection or
                                                                registration and
                                                                all rights,
                                                                title and
                                                                interest to and
                                                                in the same
                                                                absolutely in
                                                                the Company (as
                                                                sole beneficial
                                                                owner) or in any
                                                                other person as
                                                                the Company may
                                                                nominate; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                (c)
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                sign and execute
                                                                all documents
                                                                and do any acts
                                                                reasonably
                                                                required by the
                                                                Company in
                                                                connection with
                                                                any proceedings
                                                                in respect of
                                                                any applications
                                                                and any
                                                                publication or
                                                                other
                                                                application in
                                                                respect of any
                                                                Project Know-how
                                                                or Project
                                                                Rights or
                                                                protection or
                                                                registration
                                                                thereof.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.5
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant agrees to waive,
                                                as far as permissible under
                                                applicable Law, all moral rights
                                                the Consultant may have in the
                                                United Kingdom under the
                                                Copyright, Designs and Patents
                                                Act 1988, Chapter IV of Part I
                                                and any other moral or
                                                paternity, or similar or
                                                equivalent, rights in any other
                                                jurisdiction in respect of any
                                                Project Know-how or Project
                                                Rights.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                9.6
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant acknowledges
                                                that, save as provided for by
                                                law, no further fees or
                                                compensation other than those
                                                provided for under this
                                                Agreement are due or may become
                                                due to the Consultant in respect
                                                of the performance of the
                                                Consultant{"'"}s obligations
                                                under this clause.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                10
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Taxes</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                10.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                This Agreement constitutes a
                                                contract for the provision of
                                                services and not a contract of
                                                employment and accordingly the
                                                Consultant shall be fully
                                                responsible for, and shall
                                                indemnify the Company and the
                                                Group for and in respect of:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                10.1.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                any income tax,
                                                                national
                                                                insurance and
                                                                social security
                                                                contributions
                                                                and any other
                                                                liability,
                                                                deduction,
                                                                contribution,
                                                                assessment or
                                                                claim arising
                                                                from or made in
                                                                connection with
                                                                the performance
                                                                of the Services,
                                                                where the
                                                                recovery is not
                                                                prohibited by
                                                                Law. The
                                                                Consultant shall
                                                                further
                                                                indemnify the
                                                                Company against
                                                                all reasonable
                                                                costs, expenses
                                                                and any penalty,
                                                                fine or interest
                                                                incurred or
                                                                payable by the
                                                                Company in
                                                                connection with
                                                                or in
                                                                consequence of
                                                                any such
                                                                liability,
                                                                deduction,
                                                                contribution,
                                                                assessment or
                                                                claim;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                10.1.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                any liability
                                                                arising from any
                                                                employment
                                                                related claim or
                                                                any claim based
                                                                on worker status
                                                                (including
                                                                reasonable costs
                                                                and expenses)
                                                                brought by the
                                                                Consultant or
                                                                any Substitute
                                                                against the
                                                                Company arising
                                                                out of or in
                                                                connection with
                                                                the provision of
                                                                the Services.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                10.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Without prejudice to the
                                                indemnity above, if for any
                                                reason, the Company shall become
                                                liable to pay, or shall pay, any
                                                taxes or other payments referred
                                                to above, the Company shall be
                                                entitled to deduct from any
                                                amounts payable to the
                                                Consultant all amounts so paid
                                                or required to be paid by it in
                                                that respect.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                11
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Data Protection</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                11.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant consents to the
                                                Company holding and processing
                                                data relating to him for legal,
                                                personnel, administrative and
                                                management purposes and in
                                                particular to the processing of
                                                any {"'"}sensitive personal data
                                                {"'"} (as defined in the Data
                                                Protection Act 1998) relating to
                                                the Consultant including, as
                                                appropriate:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                11.1.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                information
                                                                about the
                                                                Consultant{"'"}s
                                                                physical or
                                                                mental health or
                                                                condition in
                                                                order to monitor
                                                                sickness
                                                                absence;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                11.1.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                the Consultant
                                                                {"'"}s racial or
                                                                ethnic origin or
                                                                religious or
                                                                similar beliefs
                                                                in order to
                                                                monitor
                                                                compliance with
                                                                equal
                                                                opportunities
                                                                legislation; and
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                11.1.3
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                information
                                                                relating to any
                                                                criminal
                                                                proceedings in
                                                                which the
                                                                Consultant has
                                                                been involved,
                                                                for insurance
                                                                purposes and in
                                                                order to comply
                                                                with legal
                                                                requirements and
                                                                obligations to
                                                                third parties.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                11.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant consents to the
                                                Company making such information
                                                available to those who provide
                                                products or services to the
                                                Company, such as advisers,
                                                regulatory authorities,
                                                governmental or organisations
                                                and potential purchasers of the
                                                Company or any part of its
                                                business.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                11.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Consultant consents to the
                                                transfer of such information to
                                                the Company{"'"}s business
                                                contacts outside the European
                                                Economic Area in order to
                                                further its business interests.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                12
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Termination with immediate
                                                    effect
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                12.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Company may terminate the
                                                Engagement with immediate effect
                                                with no liability to make any
                                                further payment to the
                                                Consultant (other than in
                                                respect of any amounts that
                                                accrued before the Termination
                                                Date and have become due and
                                                payable in accordance with
                                                clause 4) if at any time the
                                                Consultant:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                commits any act
                                                                or gross
                                                                misconduct
                                                                affecting
                                                                (directly or
                                                                indirectly) the
                                                                business of the
                                                                Company;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                commits any act
                                                                which negatively
                                                                affects the
                                                                reputation or
                                                                good standing of
                                                                the Company
                                                                (whether
                                                                directly or
                                                                indirectly);
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.3
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                commits any
                                                                serious or
                                                                repeated breach
                                                                or
                                                                non-observance
                                                                of any of the
                                                                provisions of
                                                                this Agreement
                                                                or the Project
                                                                Brief;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.4
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                refuses or
                                                                neglects to
                                                                comply with any
                                                                reasonable and
                                                                lawful
                                                                directions of
                                                                the Company;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.5
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                is convicted of
                                                                any criminal
                                                                offence (other
                                                                than an offence
                                                                under any road
                                                                traffic
                                                                legislation in
                                                                the United
                                                                Kingdom or
                                                                elsewhere for
                                                                which a fine or
                                                                non-custodial
                                                                penalty is
                                                                imposed);
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.6
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                is in the
                                                                reasonable
                                                                opinion of the
                                                                Company
                                                                negligent or
                                                                incompetent in
                                                                the performance
                                                                of the Services;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.7
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                is declared
                                                                bankrupt or
                                                                makes any
                                                                arrangement with
                                                                or for the
                                                                benefit of his
                                                                creditors or has
                                                                a county court
                                                                administration
                                                                order made
                                                                against him
                                                                under the County
                                                                Court Act 1984;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.8
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                commits any
                                                                fraud or
                                                                dishonesty or
                                                                acts in any
                                                                manner which in
                                                                the opinion of
                                                                the Company
                                                                brings or is
                                                                likely to bring
                                                                the Consultant
                                                                or the Company
                                                                into disrepute
                                                                or is materially
                                                                adverse to the
                                                                interests of the
                                                                Company;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.9
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                breaches any
                                                                Law; or
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                12.1.10
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                commits any
                                                                offence under
                                                                the Bribery Act
                                                                2010.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                12.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The rights of the Company under
                                                clause 12.1 are without
                                                prejudice to any other rights
                                                that it might have at law to
                                                terminate the Engagement or to
                                                accept any breach of this
                                                Agreement on the part of the
                                                Consultant as having brought the
                                                agreement to an end. Any delay
                                                by the Company in exercising its
                                                rights to terminate shall not
                                                constitute a waiver of
                                                <br />
                                                <br />
                                                these rights.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                13
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Obligations on Termination
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                13.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                Immediately upon the termination
                                                of this Agreement, the
                                                Consultant shall:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td>
                                                <table
                                                    cellSpacing='0'
                                                    cellPadding='0'
                                                    width='100%'
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                13.1.1
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                return to the
                                                                Company all
                                                                Company property
                                                                in the
                                                                Consultant{"'"}s
                                                                possession or
                                                                control;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                                width='40'
                                                            >
                                                                13.1.2
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        '8px',
                                                                }}
                                                            >
                                                                irretrievably
                                                                delete any
                                                                information
                                                                relating to the
                                                                business of the
                                                                Company stored
                                                                on any device
                                                                which is in the
                                                                Consultant
                                                                {"'"}s
                                                                possession or
                                                                control, to
                                                                include but not
                                                                be limited to
                                                                the contact
                                                                details of
                                                                business
                                                                contacts made
                                                                during the
                                                                course of
                                                                providing the
                                                                Services.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                14
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Value-Added Tax</strong>
                                                <br />
                                                The Consultant shall keep the
                                                Company informed as to changes
                                                in his Value Added Tax status
                                                and, if registered shall provide
                                                a tax invoice to the Company for
                                                all supplies made by the
                                                Consultant to the Company
                                                pursuant to this Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                15
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Counterparts</strong>
                                                <br />
                                                This Agreement may be executed
                                                in any number of counterparts.
                                                Each counterpart shall
                                                constitute an original of this
                                                Agreement but all the
                                                counterparts together shall
                                                constitute but one and the same
                                                instrument.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                16
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Assignment</strong>
                                                <br />
                                                This Agreement is personal to
                                                the parties hereto. The
                                                Consultant{"'"}s rights and
                                                obligations (whether contractual
                                                or non-contractual) under this
                                                Agreement shall be incapable of
                                                being assigned, transferred,
                                                charged or otherwise dealt with
                                                and no person shall grant,
                                                declare, create or dispose of
                                                any right or interest in this
                                                Agreement unless the Company
                                                provides its prior written
                                                consent.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                17
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Governing Law and
                                                    Jurisdiction
                                                </strong>
                                                <br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                17.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                The Agreement and any
                                                contractual or non-contractual
                                                rights or obligations arising
                                                out of or in connection with it
                                                or its subject matter or
                                                formation shall be governed by
                                                and construed in accordance with
                                                the laws of England. Each party
                                                irrevocably agrees that the
                                                courts of England and Wales
                                                shall have exclusive
                                                jurisdiction to settle any
                                                Dispute or claim arising from
                                                the same.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                17.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                For the purposes of this clause
                                                17, {'"'}
                                                <strong>Dispute</strong>
                                                {'"'}
                                                means any dispute, controversy,
                                                claim or difference of whatever
                                                nature arising out of, relating
                                                to, or having any connection
                                                with this Agreement including a
                                                dispute regarding the existence,
                                                formation, validity,
                                                interpretation, performance or
                                                termination of this Agreement or
                                                the consequences of its nullity
                                                and also including any dispute
                                                relating to any non-contractual
                                                rights or obligations arising
                                                out of, relating to or having
                                                any connection with this
                                                Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                18
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Rights of Third Parties
                                                </strong>
                                                <br />A person who is not a
                                                party to this Agreement shall
                                                have no right under the
                                                Contracts (Rights of Third
                                                Parties) Act 1999 to enforce any
                                                of its terms.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                19
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Invalidity</strong>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td style={{ padding: '8px' }}>
                                                Where any provision of this
                                                Agreement is or becomes illegal,
                                                invalid or unenforceable in any
                                                respect under the Laws of any
                                                jurisdiction then such provision
                                                shall be deemed to be severed
                                                from this Agreement and, if
                                                possible, replaced with a lawful
                                                provision which, as closely as
                                                possible, gives effect to the
                                                intention of the parties under
                                                this Agreement and, where
                                                permissible, that shall not
                                                affect or impair the legality,
                                                validity or enforceability in
                                                that, or any other, jurisdiction
                                                of any other provision of this
                                                Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                20
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>
                                                    Waiver and Variation
                                                </strong>
                                                <br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                20.1
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                A failure or delay by a party to
                                                exercise any right or remedy
                                                provided under this Agreement or
                                                by Law, whether by conduct or
                                                otherwise, shall not constitute
                                                a waiver of that or any other
                                                right or remedy, nor shall it
                                                preclude or restrict any further
                                                exercise of that or any other
                                                right or remedy. No single or
                                                partial exercise of any right or
                                                remedy provided under this
                                                Agreement or by Law, whether by
                                                conduct or otherwise, shall
                                                preclude or restrict the further
                                                exercise of that or any other
                                                right or remedy.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                20.2
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                A waiver of any right or remedy
                                                under this Agreement shall only
                                                be effective if given in writing
                                                and shall not be deemed a waiver
                                                of any subsequent breach or
                                                default.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                20.3
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                No variation or amendment of
                                                this Agreement shall be valid
                                                unless it is in writing and duly
                                                executed by or on behalf of all
                                                of the parties to this
                                                Agreement. Unless expressly
                                                agreed, no variation or
                                                amendment shall constitute a
                                                general waiver of any provision
                                                of this Agreement, nor shall it
                                                affect any rights or obligations
                                                under or pursuant to this
                                                Agreement which have already
                                                accrued up to the date of
                                                variation or amendment and the
                                                rights and obligations under or
                                                pursuant to this Agreement shall
                                                remain in full force and effect
                                                except and only to the extent
                                                that they are varied or amended.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                21
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Costs</strong>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td style={{ padding: '8px' }}>
                                                Except as otherwise provided in
                                                this Agreement, each party shall
                                                bear its own costs arising out
                                                of or in connection with the
                                                preparation, negotiation and
                                                implementation of this
                                                Agreement.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table
                                    width='100%'
                                    cellPadding='0'
                                    cellSpacing='0'
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: '8px' }}
                                                width='40'
                                            >
                                                22
                                            </td>
                                            <td style={{ padding: '8px' }}>
                                                <strong>Notices</strong>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='40'>&nbsp;</td>
                                            <td style={{ padding: '8px' }}>
                                                Any notice under or pursuant to
                                                this Agreement or in connection
                                                with the matters contemplated
                                                herein shall be provided in
                                                writing in the English language
                                                and served by delivering it
                                                personally or by sending it by
                                                registered post or recorded
                                                delivery service, in the case of
                                                the Company to the registered
                                                office of the Company and in the
                                                case of the Consultant to <br />
                                                <br />
                                                the most recent address or email
                                                address of the Consultant which
                                                was provided to the Company. Any
                                                such notice shall be deemed to
                                                be served at the expiration of
                                                forty-eight hours after it is
                                                posted. Any notice despatched
                                                outside of Working Hours shall
                                                be deemed to be posted at the
                                                start of the next period of
                                                Working Hours.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button
                    type='button'
                    className='c-btn c-btn--large c-btn--primary'
                    onClick={onHide}
                >
                    Close
                </button>
            </div>
        </Modal>
    );
}

AssociatesConsultingAgreement.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
