import React, { useEffect } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export const ControlledDropdown = ({
    children,
    trigger,
    isOpened,
    setIsOpened,
}) => {
    const ref = React.useRef();
    const menuRef = React.useRef();

    useOnClickOutside(ref, () => {
        setIsOpened(false);
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const onResize = () => {
                if (menuRef.current) {
                    const menuRect = menuRef.current.getBoundingClientRect();
                    if (window.innerWidth < menuRect.right + 30) {
                        menuRef.current.style.left = 'unset';
                        menuRef.current.style.right = `0`;
                    } else {
                        menuRef.current.style.left = `0`;
                        menuRef.current.style.right = 'unset';
                    }
                }
            };

            if (isOpened) {
                onResize();
                window.addEventListener('resize', onResize);
            }

            return () => {
                window.removeEventListener('resize', onResize);
            };
        }
    }, [isOpened]);

    return (
        <div className='c-dropdown' ref={ref}>
            <div
                onClick={() => {
                    const prev = document.body.style.overflowX;
                    document.body.style.overflowX = 'hidden';
                    setIsOpened(!isOpened);
                    setTimeout(() => {
                        document.body.style.overflowX = prev;
                    }, 0);
                }}
            >
                {trigger}
            </div>
            {isOpened && (
                <div ref={menuRef} className='c-dropdown__menu'>
                    {children}
                </div>
            )}
        </div>
    );
};
