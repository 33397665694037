import React from 'react';
import { VerificationItem } from './verification-item';
import { UniversityAffilationForm } from './university-affilation-form';
import { IdentityVerificationForm } from './identity-verification-form';
import PropTypes from 'prop-types';

export const Verification = ({
    isEmailValid,
    isUnviersityEmailProvided,
    isIdentityVerified,
    children,
    onSubmitUniversityAffilation,
    onSubmitIdentityVerificationForm,
}) => {
    const STEPS = [
        {
            label: 'Email validation',
            content: !isEmailValid && (
                <div className='c-verification-item__content'>
                    <div>
                        <div className='c-verification-item__semibold'>
                            Confirm we’ve got your email right
                        </div>
                        <div>
                            Look for the email from Join Associates in your
                            inbox and click the link in the email to activate
                            your account.
                        </div>
                    </div>
                    <div>
                        <div>
                            Didn’t get the email? Check your spam folder or
                            contact us on
                        </div>
                        <a href='mailto:join.associates@outsmartinsight.com'>
                            join.associates@outsmartinsight.com
                        </a>
                    </div>
                </div>
            ),
            isCompleted: isEmailValid,
        },
        {
            label: 'University affiliation',
            content: isEmailValid && !isUnviersityEmailProvided && (
                <div className='c-verification-item__content'>
                    <div>
                        <div>
                            Please provide your university (or alumni) email
                            address. We use this for the purpose of confirming
                            your academic affiliation only.{' '}
                        </div>
                    </div>
                    <div>
                        <div>
                            If you need an alternative method to verify your
                            academic affiliation, contact us on{' '}
                            <a href='mailto:join.associates@outsmartinsight.com'>
                                join.associates@outsmartinsight.com
                            </a>
                        </div>

                    </div>
                    <UniversityAffilationForm
                        onSubmit={onSubmitUniversityAffilation}
                    />
                </div>
            ),
            isCompleted: isUnviersityEmailProvided,
        },
        {
            label: 'Identity verification',
            content: isEmailValid &&
                isUnviersityEmailProvided &&
                !isIdentityVerified && (
                    <div className='c-verification-item__content'>
                        <div>
                            By law, we require a photo of a government ID to
                            verify your identity before you start earning fees.
                            This information will be stored for no more than 12
                            months.
                        </div>
                        <div>
                            When taking a photograph of your passport ID page or
                            equivalent national identity card (but not a driving
                            licence), make sure it is:
                        </div>
                        <ul className='c-register__list'>
                            <li>Clear and in focus</li>
                            <li>Taken from above</li>
                            <li>On a clear white background</li>
                        </ul>
                        <div>
                            <div>If you need assistance, contact us on</div>
                            <a href='mailto:join.associates@outsmartinsight.com'>
                                join.associates@outsmartinsight.com
                            </a>
                        </div>
                        <div>
                            You may log in at anytime in the next 5 days to do
                            this. After 5 days your application is automatically
                            deleted.
                        </div>
                        <IdentityVerificationForm
                            onSubmit={onSubmitIdentityVerificationForm}
                        />
                    </div>
                ),
            isCompleted: isIdentityVerified,
        },
    ];
    return (
        <>
            <div className='c-content-media__content c-register__verificationSubtitle'>
                Last step is to verify who you are, and we’re done.
            </div>

            <div>
                {STEPS.map((s, i) => (
                    <VerificationItem
                        key={s.label}
                        label={s.label}
                        num={i + 1}
                        content={s.content}
                        isCompleted={s.isCompleted}
                    />
                ))}
                {children}
            </div>
        </>
    );
};

Verification.propTypes = {
    isEmailValid: PropTypes.bool.isRequired,
    isUnviersityEmailProvided: PropTypes.bool.isRequired,
    isIdentityVerified: PropTypes.bool.isRequired,
    children: PropTypes.node,
    onSubmitUniversityAffilation: PropTypes.func.isRequired,
    onSubmitIdentityVerificationForm: PropTypes.func.isRequired,
};
