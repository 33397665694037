/**
 * Makes an api call to check an invitation code
 *
 * @module api/register
 * @param invitationCode
 * @returns {Promise<Response>}
 */
async function checkInvitationCode(invitationCode) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/preregister/check-invitation/${invitationCode}/`, {
        method: 'GET',
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

/**
 * Makes API call to register login details
 *
 * @module api/register
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function registerLoginDetails(data = {}) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/auth/register/login_details/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Makes API call to register personal details
 *
 * @module api/register
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function registerPersonalDetails(data = {}) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/auth/register/personal_details/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

/**
 * Makes API call to agree IWP
 * @module api/register
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function agreeIWP() {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/auth/register/agree_iwp/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

/**
 * Makes API call to register credentials
 *
 * @module api/register
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function registerCredentials(data = {}) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/auth/register/credentials/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Makes API call to add further academic experience
 *
 * @module api/register
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function addFurtherAcademicExperience(data = []) {
    const obj = {'objects': data};

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/smartie/academic-experience/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: JSON.stringify(obj),
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

/**
 * Make API call to verify university
 */
async function verifyUniversity(data = {}) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/me/verify_university/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Make api call to upload an ID document
 */
async function uploadDocument(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/me/document_upload/`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
        body: formData,
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

/**
 * Make api call to agree T&Cs
 * @returns {Promise<Response>}
 */
async function agreeTandCs() {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/me/agree_terms/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


export {
    checkInvitationCode,
    registerLoginDetails,
    registerPersonalDetails,
    agreeIWP,
    registerCredentials,
    addFurtherAcademicExperience,
    verifyUniversity,
    uploadDocument,
    agreeTandCs,
}