import React from 'react';
import PropTypes from 'prop-types';

export const ApplicationSubmitted = ({ onClickGoToProfile }) => {
    return (
        <div className='c-register__applicationSubmitted'>
            <div>
                You have successfully submitted all the information required for
                your application to become an Associate with Outsmart Insight.
            </div>
            <div>
                Our team will shortly review your application and you will hear
                from us by email within 5 days. You can start joining projects
                then.{' '}
            </div>
            <div>
                In the meantime, give yourself the best chance of being approved
                by customising your profile with{' '}
                <span className='c-register__semibold'>
                    skills you like using
                </span>{' '}
                and types of{' '}
                <span className='c-register__semibold'>
                    work you enjoy doing
                </span>
                . This way, you’ll receive invitations to projects that best
                match your interests.{' '}
            </div>
            <div>You can customise your profile from the next page.</div>
            <div>
                <button
                    onClick={onClickGoToProfile}
                    className='c-contact__submit c-btn c-btn--large c-btn--primary'
                >
                    GO TO PROFILE
                </button>
            </div>
        </div>
    );
};

ApplicationSubmitted.propTypes = {
    onClickGoToProfile: PropTypes.func.isRequired,
};
