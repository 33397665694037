import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {format} from "date-fns";
import {FormProvider, useForm} from 'react-hook-form';
import { useMutation } from "react-query";
import {
    CURRENT_STATUS_ALUMNI,
    CURRENT_STATUS_CHOICES, CURRENT_STATUS_RESEARCH_STAFF,
    CURRENT_STATUS_STUDENT,
    EDUCATION_STATUS_ALUMNI,
    EDUCATION_STATUS_RESEARCH
} from '../../../constants';
import { AcademicExperience } from './academic-experience';
import { Collapse } from '../../collapse/collapse';
import { PhdCanidateFields } from './phd-canidate-fields';
import { PostDocFields } from './post-doc-fields';
import { AlumFields } from './alum-fields';
import {useAuth} from "../../../contexts/auth";
import {addFurtherAcademicExperience, registerCredentials, registerPersonalDetails} from "../../../api/register";

export const CredentialsStep = ({ subtitle, onFormSubmit }) => {
    const [academicExperienceOpened, setAcademicExperienceOpened] =
        useState(false);
    const [secondAcademicExperienceOpened, setSecondAcademicExperienceOpened] =
        useState(false);
    const methods = useForm({
        defaultValues: {
            additional_experiences: [
                {
                    university: '',
                    department: '',
                    degree_level: '',
                    degree_field: '',
                },
                {
                    university: '',
                    department: '',
                    degree_level: '',
                    degree_field: '',
                },
            ],
        }
    });
    const {
        formState: { errors },
        register,
        control,
        handleSubmit,
        setError,
    } = methods;

    const { user } = useAuth();

    const renderCurrentStatus = (status) => {
        switch (status) {
            case EDUCATION_STATUS_RESEARCH:
                return CURRENT_STATUS_CHOICES.find((item) => item[0] === CURRENT_STATUS_RESEARCH_STAFF)[1];
            case EDUCATION_STATUS_ALUMNI:
                return CURRENT_STATUS_CHOICES.find((item) => item[0] === CURRENT_STATUS_ALUMNI)[1];
            default:
                return CURRENT_STATUS_CHOICES.find((item) => item[0] === CURRENT_STATUS_STUDENT)[1];
        }
    }

    const registerCredentialsMutation = useMutation({
        mutationKey: 'registerCredentials',
        mutationFn: registerCredentials,
        onSuccess: (data) => {
            onFormSubmit(data);
        },
        onError: (error) => {
            const formErrors = error.response?.form_errors;

            if (formErrors && typeof formErrors === 'object') {
                Object.keys(formErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: formErrors[key][0],
                    });
                });
            } else {
                setError('non_field_errors', {
                    type: 'manual',
                    message: 'Server Error - Please try again later',
                });
            }
        },
    });

    const academicExperienceMutation = useMutation({
        mutationKey: 'academicExperience',
        mutationFn: addFurtherAcademicExperience
    });

    const renderFields = () => {
        switch (user?.student_status) {
            case EDUCATION_STATUS_RESEARCH:
                return <PostDocFields />;
            case EDUCATION_STATUS_ALUMNI:
                return <AlumFields />;
            default:
                return <PhdCanidateFields />;
        }
    };

    const onSubmit = (data) => {
        // Make an immutable copy of the data
        data = JSON.parse(JSON.stringify(data));

        if (data['degree_start']) {
            data['degree_start'] = format(data['degree_start'], 'yyyy-MM-dd');
        }

        if (data['degree_end']) {
            data['degree_end'] = format(data['degree_end'], 'yyyy-MM-dd');
        }

        // Iterate over the additional experiences and format the date
        data['additional_experiences'] = data['additional_experiences'].map((experience) => {
            if (experience['degree_start']) {
                experience['degree_start'] = format(experience['degree_start'], 'yyyy-MM-dd');
            }
            if (experience['degree_end']) {
                experience['degree_end'] = format(experience['degree_end'], 'yyyy-MM-dd');
            }
            return experience;
        });

        const { additional_experiences: academicExperience, ...credentials } = data;

        // Filter academic experiences where nothing is filled in
        const filteredAcademicExperiences = academicExperience.filter(
            (experience) => Object.values(experience).some((value) => value)
        );

        registerCredentialsMutation.mutate(credentials);
        academicExperienceMutation.mutate(filteredAcademicExperiences);
    };

    return (
        <FormProvider {...methods}>
            <form
                className='c-form'
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='c-content-media__content c-register__credentialsSubtitle'>
                    Please tell our team about your academic and professional
                    experience.
                </div>
                <div className='c-register__addressDetailsTitle'>
                    <h3 className='c-project-listing__item-title'>
                        Academic experience
                    </h3>
                    <div className='c-content-media__content c-register__hint'>
                        {subtitle}
                    </div>
                </div>
                <div className='row'>
                    <div className='f-field c-preregister__w-full'>
                        <div className='f-field__elem'>
                            <input
                                name='university'
                                className='f-control'
                                type='text'
                                placeholder='university'
                                value={user?.academic_experiences[0]?.university}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='f-field c-preregister__w-full'>
                        <div className='f-field__elem'>
                            <input
                                name='department'
                                className='f-control'
                                type='text'
                                placeholder='department'
                                value={user?.academic_experiences[0]?.department}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='f-field c-preregister__w-full'>
                        <div className='f-field__elem'>
                            <input
                                value={renderCurrentStatus(user?.student_status)}
                                name='status'
                                className='f-control'
                                type='text'
                                placeholder='status'
                                disabled
                            />
                        </div>
                    </div>
                </div>
                {renderFields()}
                {/* <p className='c-contact__form-consent'>
                    The following sections may be added to your profile at a later
                    stage. Completing it now will enable us to better match you to
                    open projects.
                </p> */}
                <div className='c-content-media__content c-register__hint c-register__followingSectionsHint'>
                    The following sections may be added to your profile at a later
                    stage. Completing it now will enable us to better match you to
                    open projects.
                </div>
                <AcademicExperience
                    index={0}
                    className='c-register__AcademicExperience'
                    setIsOpened={setAcademicExperienceOpened}
                    isOpened={academicExperienceOpened}
                />
                {(academicExperienceOpened || secondAcademicExperienceOpened) && (
                    <AcademicExperience
                        index={1}
                        className='c-register__AcademicExperience'
                        setIsOpened={setSecondAcademicExperienceOpened}
                        isOpened={secondAcademicExperienceOpened}
                    />
                )}
                <div className='c-register__AcademicExperience'>
                    <Collapse
                        label='Add professional experience'
                        className='c-register__collapse'
                    >
                        <div className='c-register__collapse-box'>
                            <textarea
                                rows='3'
                                id='body'
                                name='experience'
                                placeholder='Please add a few sentences about the commercial, business or professional experience you have attained so far.'
                                className={
                                    errors.experience
                                        ? 'c-form__textarea error'
                                        : 'c-form__textarea'
                                }
                                {...register('experience')}
                            />
                            {errors.experience && (
                                <span className='f-error'>
                                    {errors.experience.message}
                                </span>
                            )}
                        </div>
                    </Collapse>
                </div>
                <div className='c-preregister__form-footer'>
                    <button
                        type='submit'
                        className='c-btn c-btn--large c-btn--primary c-preregister__submit'
                    >
                        Next
                    </button>

                    {errors.__all__ &&
                        <span className='f-error'>
                            {errors.__all__.message}
                        </span>
                    }

                    {errors.non_field_errors &&
                        <span className='f-error'>
                            {errors.non_field_errors.message}
                        </span>
                    }
                </div>
            </form>
        </FormProvider>
    );
};

CredentialsStep.propTypes = {
    subtitle: PropTypes.string.isRequired,
};
