import React from 'react';

import classNames from 'classnames';
import { SelectField } from '../../fields/select-field';
import { useFormContext, Controller } from 'react-hook-form';
import {
    DEEGREE_CHOICES,
} from '../../../constants';
import PropTypes from 'prop-types';
import { DatePicker } from '../../date-picker';
import { startOfDay, subDays } from 'date-fns';
import { ControlledCollapse } from '../../collapse/controlled-collapse';

export const AcademicExperience = ({
    index,
    className = '',
    isOpened,
    setIsOpened,
}) => {
    const {
        formState: { errors },
        setValue,
    } = useFormContext();


    const onChangeField = (field, name) => (v) => {
        setValue(
            'additional_experiences',
            field.value.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        [name]: v,
                    };
                }
                return item;
            })
        );
    };

    const maxDate = startOfDay(subDays(new Date(), 1));

    return (
        <div className={className}>
            <ControlledCollapse
                setIsOpened={setIsOpened}
                isOpened={isOpened}
                label='Add further academic experience'
                className='c-register__collapse'
            >
                <div className='c-register__collapse-box'>
                    <div className='c-register__collapse-content'>
                        <div
                            className={classNames('f-field__elem', {
                                'f-field__elem--has-error': errors.university,
                            })}
                        >
                            <Controller
                                name='additional_experiences'
                                render={({ field }) => {
                                    return (
                                        <input
                                            value={field.value[index].university}
                                            className='f-control'
                                            type='text'
                                            placeholder='University'
                                            onChange={(e) => {
                                                onChangeField(
                                                    field,
                                                    'university'
                                                )(e.target.value);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className='f-field__elem'>
                            <Controller
                                name='additional_experiences'
                                render={({ field }) => (
                                    <input
                                        value={field.value[index].department}
                                        className='f-control'
                                        type='text'
                                        placeholder='Department'
                                        onChange={(e) => {
                                            onChangeField(
                                                field,
                                                'department'
                                            )(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div
                            className={classNames('f-field__elem', {
                                'f-field__elem--has-error': errors.degree_level,
                            })}
                        >
                            <Controller
                                name='additional_experiences'
                                render={({ field }) => {
                                    return (
                                        <SelectField
                                            value={field.value[index].degree_level}
                                            onChange={onChangeField(
                                                field,
                                                'degree_level'
                                            )}
                                            data={DEEGREE_CHOICES}
                                            placeholder='Degree level'
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className='f-field__elem'>
                            <Controller
                                name='additional_experiences'
                                render={({ field }) => (
                                    <input
                                        value={
                                            field.value[index].degree_field
                                        }
                                        className='f-control'
                                        type='text'
                                        placeholder='Field of study/research'
                                        onChange={(e) => {
                                            onChangeField(
                                                field,
                                                'degree_field'
                                            )(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className='row'>
                            <div
                                className='f-field'
                                style={{
                                    margin: 0,
                                }}
                            >
                                <div className='f-field__elem'>
                                    <Controller
                                        name='additional_experiences'
                                        render={({ field }) => (
                                            <DatePicker
                                                value={
                                                    field.value[index]
                                                        .degree_end
                                                }
                                                maxDate={maxDate}
                                                placeholder='Completion date'
                                                onChange={onChangeField(
                                                    field,
                                                    'degree_end'
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ControlledCollapse>
        </div>
    );
};

AcademicExperience.propTypes = {
    index: PropTypes.number.isRequired,
};
