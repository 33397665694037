import classNames from 'classnames';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '../../date-picker';
import { startOfDay, subDays, addDays } from 'date-fns';

export const PhdCanidateFields = () => {
    const {
        formState: { errors },
        register,
        setValue
    } = useFormContext();
    const maxStartDate = startOfDay(subDays(new Date(), 1));
    const minEndDate = startOfDay(addDays(new Date(), 1));
    return (
        <>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_field,
                        })}
                    >
                        <input
                            name='degree_field'
                            className='f-control'
                            type='text'
                            placeholder='Field of study/research
                '
                            {...register('degree_field', {
                                required: 'This field is required',
                            })}
                        />
                        {errors.degree_field && (
                            <span className='f-error'>
                                {errors.degree_field.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_start,
                        })}
                    >
                        <Controller
                            name='degree_start'
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <DatePicker
                                    onChange={(v) => {
                                        setValue('degree_start', v);
                                    }}
                                    value={field.value}
                                    maxDate={maxStartDate}
                                    placeholder='Start date'
                                />
                            )}
                        />
                        {errors.degree_start && (
                            <span className='f-error'>
                                {errors.degree_start.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_end,
                        })}
                    >
                        <Controller
                            name='degree_end'
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <div className='c-register__datePickerPlaceholder'>
                                    <DatePicker
                                        onChange={(v) => {
                                            setValue('degree_end', v);
                                        }}
                                        value={field.value}
                                        minDate={minEndDate}
                                        placeholder='Completion date'
                                    />
                                </div>
                            )}
                        />
                        {errors.degree_end && (
                            <span className='f-error'>
                                {errors.degree_end.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
