import { useState, useEffect } from "react";
import queryString from "query-string";
import { useQuery } from "react-query";
import { checkInvitationCode } from "../api/register";
import {useAuth} from "../contexts/auth";


/**
 * Retrieve the invitation code from the URL query string and check if it is valid
 */
export default function useInvitationCode() {
    // Retrieve invitationCode from querystring
    const [ invitationCode, setInvitationCode ] = useState(null);

    useEffect(() => {
        const queryStringObject = queryString.parse(window.location.search);

        if ('invitation_code' in  queryStringObject) {
            setInvitationCode(queryStringObject.invitation_code);
        }
    }, []);

    // Return array with invitationCode and query
    return [
        invitationCode,
        useQuery(
            ['invitationCode', invitationCode],
            async () => {
                return await checkInvitationCode(invitationCode);
            }, {
                enabled: invitationCode !== null,
                retry: false,
                // Cache the result for 24 hours
                cacheTime: 1000 * 60 * 60 * 24,
                staleTime: 1000 * 60 * 60 * 24,
        }),
    ]
}