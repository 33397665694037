import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useMutation } from "react-query";
import { SelectField } from '../../fields/select-field';
import { COUNTRY_CHOICES, TITLE_CHOICES_CUTTED } from '../../../constants';
import lockImg from '../../../images/padlock-grey.png';
import {useAuth} from "../../../contexts/auth";
import { registerPersonalDetails } from "../../../api/register";

export const PersonalDetailsStep = ({ onFormSubmit }) => {
    const {
        control,
        formState: { errors },
        register,
        handleSubmit,
        setError,
    } = useForm();

    const { user } = useAuth();

    const registerPersonalDetailsMutation = useMutation({
        mutationKey: 'registerPersonalDetails',
        mutationFn: async (data) => {
            await registerPersonalDetails(data);
            onFormSubmit(data);
        },
        onError: (error) => {
            const formErrors = error.response?.form_errors;

            if (formErrors && typeof formErrors === 'object') {
                Object.keys(formErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: formErrors[key][0],
                    });
                });
            } else {
                setError('non_field_errors', {
                    type: 'manual',
                    message: 'Server Error - Please try again later',
                });
            }
        }
    });

    const onSubmit = (data) => {
        registerPersonalDetailsMutation.mutate(data);
    };

    return (
        <form
            className='c-form'
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className='row'>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem')}
                    >
                        <input
                            className='f-control'
                            type='text'
                            placeholder='First name'
                            value={user?.user?.first_name}
                            disabled
                        />
                    </div>
                </div>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem')}
                    >
                        <input
                            className='f-control'
                            type='text'
                            placeholder='Last name'
                            value={user?.user?.last_name}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem')}
                    >
                        <input
                            className='f-control'
                            type='text'
                            placeholder='Nationality'
                            value={user?.country_of_residence ? COUNTRY_CHOICES[user?.country_of_residence] : ''}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.title,
                        })}
                    >
                        <Controller
                            control={control}
                            name='title'
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({field}) => (
                                <SelectField
                                    {...field}
                                    data={TITLE_CHOICES_CUTTED}
                                    placeholder='Title'
                                />
                            )}
                        />
                        {errors.title && (
                            <span className='f-error'>
                                {errors.title.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='c-register__addressDetailsTitle'>
                <h3 className='c-project-listing__item-title'>
                    Address details
                </h3>
            </div>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.addr_first_line,
                        })}
                    >
                        <input
                            name='addr_first_line'
                            className='f-control'
                            type='text'
                            placeholder='Address line 1'
                            {...register('addr_first_line', {
                                required: 'This field is required',
                            })}
                        />
                        {errors.addr_first_line && (
                            <span className='f-error'>
                                {errors.addr_first_line.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div className={classNames('f-field__elem', {
                        'f-field__elem--has-error': errors.addr_second_line,
                    })}>
                        <input
                            name='addr_second_line'
                            className='f-control'
                            type='text'
                            placeholder='Address line 2'
                            {...register('addr_second_line')}
                        />
                        {errors.addr_second_line && (
                            <span className='f-error'>
                                {errors.addr_second_line.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.addr_city,
                        })}
                    >
                        <input
                            name='addr_city'
                            className='f-control'
                            type='text'
                            placeholder='City'
                            {...register('addr_city', {
                                required: 'This field is required',
                            })}
                        />
                        {errors.addr_city && (
                            <span className='f-error'>
                                {errors.addr_city.message}
                            </span>
                        )}
                    </div>
                </div>
                {' '}
                <div className='f-field c-preregister__w-full'>
                    <div className={classNames('f-field__elem', {
                        'f-field__elem--has-error': errors.addr_postcode,
                    })}>
                        <input
                            name='addr_postcode'
                            className='f-control'
                            type='text'
                            placeholder='Postal / Zip code'
                            {...register('addr_postcode')}
                        />
                        {errors.addr_postcode && (
                            <span className='f-error'>
                                {errors.addr_postcode.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.addr_country,
                        })}
                    >
                        <Controller
                            control={control}
                            name='addr_country'
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({field}) => (
                                <SelectField
                                    {...field}
                                    data={COUNTRY_CHOICES}
                                    placeholder='Country'
                                />
                            )}
                        />
                        {errors.addr_country && (
                            <span className='f-error'>
                                {errors.addr_country.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='c-register__hintWithImg'>
                <img
                    src={lockImg}
                    alt=''
                    className='c-register__lockImg'
                    loading='lazy'
                />
                <p className='c-contact__form-consent'>
                    Address details will be included in your consulting
                    agreement. Your personal information is securely stored and
                    kept confidential.
                </p>
            </div>
            <div className='c-preregister__form-footer'>
                <button
                    type='submit'
                    className='c-btn c-btn--large c-btn--primary c-preregister__submit'
                >
                    Next
                </button>

                {errors.__all__ &&
                    <span className='f-error'>
                        {errors.__all__.message}
                    </span>
                }

                {errors.non_field_errors &&
                    <span className='f-error'>
                        {errors.non_field_errors.message}
                    </span>
                }
            </div>
        </form>
    );
};
