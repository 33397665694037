import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const VerificationItem = ({ label, num, content, isCompleted }) => {
    return (
        <div
            className={classNames('c-verification-item', {
                'c-verification-item--completed': isCompleted,
            })}
        >
            <div className='c-verification-item__row'>
                <div className='c-verification-item__circle'>
                    {isCompleted ? '✓' : num}
                </div>
                <div className='c-verification-item__label'>{label}</div>
            </div>
            {!isCompleted && content}
        </div>
    );
};

VerificationItem.propTypes = {
    label: PropTypes.string.isRequired,
    num: PropTypes.number.isRequired,
};
