/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import InternationalWorkingPolicyContent from './InternationalWorkingPolicyContent';

Modal.setAppElement('body');

export default function InternationalWorkingPolicy({ showModal, onHide }) {
    return (
        <Modal isOpen={showModal} onRequestClose={onHide} className='ModalIWP'>
            <button type='button' className='btn-close' onClick={onHide}>
                <span aria-hidden='true'>&times;</span>
            </button>

            <InternationalWorkingPolicyContent />

            <button
                type='button'
                className='c-btn c-btn--large c-btn--primary'
                onClick={onHide}
            >
                Close
            </button>
        </Modal>
    );
}

InternationalWorkingPolicy.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
