import React, { useEffect, useState } from 'react';
import { StartStep } from './start-step';
import { CredentialsStep } from './credentials-step';
import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import { StepWrapper } from '../step-wrapper';
import PersoanlDetailsImg from '../../../images/register/personal-details.png';
import CredentialsImg from '../../../images/register/credentials.png';
import ApplicationSubmittedImg from '../../../images/register/application-submitted.png';
import VerificationFinishedImg from '../../../images/register/verification-finished.png';
import { PersonalDetailsStep } from './personal-details-step';
import InternationalWorkingPolicy from '../../modals/InternationalWorkingPolicy';
import PrivacyPolicy from '../../modals/PrivacyPolicy';
import ConsultingAgreement from '../../modals/AssociatesConsultingAgreement';
import {
    DEFAULT_COUNTRY_LOCATION,
    EDUCATION_STATUS_ALUMNI,
    EDUCATION_STATUS_RESEARCH,
    EDUCATION_STATUS_STUDENT, ELIGIBLE_COUNTRIES
} from '../../../constants';
import { Verification } from './verification';
import { Testimonial } from '../preregister/testimonial';
import Coin from '../../../images/register/coin.png';
import Graph from '../../../images/register/graph.png';
import Medal from '../../../images/register/medal.png';
import Mountain from '../../../images/register/mountain.png';
import Passport from '../../../images/register/passaport.png';
import { ApplicationSubmitted } from './application-submitted';
import {useAuth} from "../../../contexts/auth";
import { InternationalWorkingPolicyPage } from './international-working-policy-page';
import {agreeTandCs} from "../../../api/register";

export const DATA_KEY = 'preregister_data';

const CREDS_DATA = {
    [EDUCATION_STATUS_STUDENT]: {
        subtitle: 'What are you currently working towards?',
    },
    [EDUCATION_STATUS_RESEARCH]: {
        subtitle: 'What are you currently working on?',
    },
    [EDUCATION_STATUS_ALUMNI]: {
        subtitle: 'What degree have you attained most recently',
    },
};

const REGISTRATION_LOGIN_DETAILS_PAGE = 1
const REGISTRATION_PERSONAL_DETAILS_PAGE = 2
const REGISTRATION_CREDENTIALS_PAGE = 3
const REGISTRATION_VERIFICATION_PAGE = 4
const REGISTRATION_IWP_PAGE = 5

export const RegisterForm = ({
    image,
    summary,
    title,
}) => {
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isUnviersityEmailProvided, setIsUnviersityEmailProvided] =
        useState(false);
    const [isIdentityVerified, setIsIdentityVerified] = useState(false);
    const { user, setToken, isLoggedIn, refreshUser } = useAuth();

    const [step, setStep] = useState(0);
    const [showIwpModal, setShowIwpModal] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showConsultingAgreement, setShowConsultingAgreement] =
        useState(false);
    const [
        isInternationalWorkingPolicyPageShown,
        setIsInternationalWorkingPolicyPageShown,
    ] = useState(false);

    const agreeTermsMutation = useMutation({
        mutationKey: 'agreeTerms',
        mutationFn: agreeTandCs,
        onSuccess: () => {
            onSubmit();
        },
    })

    const credsData = user?.student_status
        ? CREDS_DATA[user.student_status]
        : CREDS_DATA[EDUCATION_STATUS_STUDENT];

    useEffect(() => {
        if (isLoggedIn) {
            const { is_registration_incomplete: isRegistrationIncomplete, registration_page: registrationPage } = user;

            if (isRegistrationIncomplete) {
                switch (registrationPage) {
                    case REGISTRATION_PERSONAL_DETAILS_PAGE:
                        setStep(1)
                        break;
                    case REGISTRATION_IWP_PAGE:
                        setStep(1)
                        setIsInternationalWorkingPolicyPageShown(true)
                        break;
                    case REGISTRATION_CREDENTIALS_PAGE:
                        setStep(2)
                        break;
                    case REGISTRATION_VERIFICATION_PAGE:
                        setStep(3)
                        break;
                }
            } else {
                setStep(4);
            }
        }
    }, []);

    useEffect(() => {
        if (user?.user?.is_active) {
            setIsEmailValid(true);
        }

        if (user?.university_verified) {
            setIsUnviersityEmailProvided(true);
        }

        if (user?.identity_upload || user?.identity_verified) {
            setIsIdentityVerified(true);
        }
    }, [user]);

    const onSubmit = (data) => {
        if (step === 0) {
            // Set token into the auth provider
            setToken(data['auth_token']);
        }

        refreshUser();

        // Display IWP page if user has no residence in eligible countries and address is the default country location
        if (step === 1 && !ELIGIBLE_COUNTRIES.includes(user?.country_of_residence)
                && data && data['addr_country'] === DEFAULT_COUNTRY_LOCATION && !isInternationalWorkingPolicyPageShown) {
            setIsInternationalWorkingPolicyPageShown(true);
            return;
        }

        setStep((p) => p + 1);
    };


    const Steps = [
        {
            component: (
                <StartStep
                    image={image}
                    summary={summary}
                    title={title}
                    onFormSubmit={onSubmit}
                />
            ),
        },
        {
            title: 'Personal details',
            component: <PersonalDetailsStep onFormSubmit={onSubmit} />,
            leftColumn: (
                <Testimonial
                    img={PersoanlDetailsImg}
                    quote="I'm a postdoc researching chemical oceanography and geochemistry. I am interested in reconstructing pollution pathways from land to the ocean."
                    name='Timothy Grey'
                    title='Postdoc, University of Liverpool'
                >
                    <div className='c-register__testiomonialDesc'>
                        {[
                            {
                                text: '89th on the Leaderboard',
                                icon: Graph,
                            },
                            {
                                text: '22 Projects contributed',
                                icon: Coin,
                            },
                            {
                                text: '#1 Skill: Conceptual thinking',
                                icon: Medal,
                            },
                            {
                                text: 'Ambition: Marine Biologist',
                                icon: Mountain,
                            },
                        ].map(({ text, icon }) => {
                            return (
                                <div
                                    key={text}
                                    className='c-register__testiomonialItem'
                                >
                                    <img
                                        src={icon}
                                        className='c-register__icon'
                                    />
                                    <div>{text}</div>
                                </div>
                            );
                        })}
                    </div>
                </Testimonial>
            ),
        },
        {
            title: 'Credentials',
            component: (
                <CredentialsStep
                    subtitle={credsData.subtitle}
                    onFormSubmit={onSubmit}
                />
            ),
            leftColumn: (
                <Testimonial
                    img={CredentialsImg}
                    quote='I’m a Physics PhD student, working on organic semiconductor devices for applications in flexible displays, biosensors and wearables.'
                    name='Lulu Ren'
                    title='PhD, Imperial College London'
                >
                    <div className='c-register__testiomonialDesc'>
                        {[
                            {
                                text: '223rd on the Leaderboard',
                                icon: Graph,
                            },
                            {
                                text: '7 Projects contributed',
                                icon: Coin,
                            },
                            {
                                text: '#1 Skill: Science writing',
                                icon: Medal,
                            },
                            {
                                text: 'Ambition: Fiction author',
                                icon: Mountain,
                            },
                        ].map(({ text, icon }) => {
                            return (
                                <div
                                    key={text}
                                    className='c-register__testiomonialItem'
                                >
                                    <img
                                        src={icon}
                                        className='c-register__icon'
                                    />
                                    <div>{text}</div>
                                </div>
                            );
                        })}
                    </div>
                </Testimonial>
            ),
        },
        {
            title: 'Verification',
            component: (
                <Verification
                    onSubmitUniversityAffilation={(data) => {}}
                    onSubmitIdentityVerificationForm={(data) => {
                        setIsIdentityVerified(true);
                        refreshUser();

                        // Scroll to div.c-preregister__title
                        if (typeof window !== 'undefined') {
                            const el = document.querySelector('.c-register__form');
                            el.scrollIntoView();
                        }
                    }}
                    isEmailValid={isEmailValid}
                    isUnviersityEmailProvided={isUnviersityEmailProvided}
                    isIdentityVerified={isIdentityVerified}
                >
                    {isIdentityVerified && (
                        <div className='c-register__submitApplication'>
                            <p className='c-contact__form-consent'>
                                You agree to the terms and conditions of our{' '}
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowPrivacyPolicy(true);
                                    }}
                                >
                                    Privacy Policy
                                </a>
                                ,{' '}
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowIwpModal(true);
                                    }}
                                >
                                    International Working Policy
                                </a>{' '}
                                and{' '}
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowConsultingAgreement(true);
                                    }}
                                >
                                    Consulting Agreement
                                </a>
                                .
                            </p>
                            <div>
                                <button
                                    type='submit'
                                    className='c-contact__submit c-btn c-btn--large c-btn--primary'
                                    onClick={() => {
                                        agreeTermsMutation.mutate();
                                    }}
                                >
                                    Submit application
                                </button>
                                {agreeTermsMutation.isError && (
                                    <div className='f-error'>
                                        Server error. Please try again later.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Verification>
            ),
            leftColumn:
                isEmailValid &&
                isUnviersityEmailProvided &&
                !isIdentityVerified ? (
                    <div className='c-testimonial-carousel__content-container c-preregister__testimonial'>
                        <img src={Passport} className='c-register__passport' />
                    </div>
                ) : isIdentityVerified ? (
                    <div className={'c-testimonial-carousel__content-container c-preregister__testimonial'} />
                ) : (
                    <Testimonial
                        img={VerificationFinishedImg}
                        quote='I have just finished my PhD on stem cell biology and computation to map stem cells of amniotic fluid using single-cell RNA sequencing.'
                        name='Megan Harris'
                        title='Alum, PhD at Georgia Tech'
                    >
                        <div className='c-register__testiomonialDesc'>
                            {[
                                {
                                    text: '351st on the Leaderboard',
                                    icon: Graph,
                                },
                                {
                                    text: '3 Projects contributed',
                                    icon: Coin,
                                },
                                {
                                    text: '#1 Skill: Interviewing tech startups',
                                    icon: Medal,
                                },
                                {
                                    text: 'Ambition: Rockstar (but will settle for musician!)',
                                    icon: Mountain,
                                },
                            ].map(({ text, icon }) => {
                                return (
                                    <div
                                        key={text}
                                        className='c-register__testiomonialItem'
                                    >
                                        <img
                                            src={icon}
                                            className='c-register__icon'
                                        />
                                        <div>{text}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </Testimonial>
                ),
        },
        {
            title: 'Application submitted',
            component: (
                <ApplicationSubmitted
                    onClickGoToProfile={() => {
                        window.location.href = `${process.env.GATSBY_ASSOCIATES_URL}profile/`;
                    }}
                />
            ),
            leftColumn: (
                <div className={'c-testimonial-carousel__content-container c-preregister__testimonial'}/>
            ),
        },
    ];

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo({
                behavior: 'auto',
                top: 0,
                left: 0,
            });
        }
        // eslint-disable-next-line
    }, [step, isInternationalWorkingPolicyPageShown]);

    const renderSteps = () => {
        const content =
            step === 1 && isInternationalWorkingPolicyPageShown ? (
                <InternationalWorkingPolicyPage
                    step={step - 1}
                    stepsLength={Steps.length - 1}
                    onClick={() => {
                        onSubmit()
                    }}
                />
            ) : (
                <StepWrapper
                    step={step - 1}
                    stepsLength={Steps.length - 1}
                    btnLabel={step === Steps.length - 1 ? 'Submit' : 'Next'}
                    title={curStep.title}
                    topText={curStep.topText}
                    leftColumn={curStep.leftColumn}
                    hasSubmitBtn={false}
                    className={'c-register--started'}
                >
                    {curStep.component}
                </StepWrapper>
            );

        return step === 0 ? curStep.component : content;
    };

    const curStep = Steps[step];
    return (
        <>
            {renderSteps()}
            <InternationalWorkingPolicy
                showModal={showIwpModal}
                onHide={() => {
                    setShowIwpModal(false);
                }}
            />
            <PrivacyPolicy
                onHide={() => setShowPrivacyPolicy(false)}
                showModal={showPrivacyPolicy}
            />
            <ConsultingAgreement
                onHide={() => setShowConsultingAgreement(false)}
                showModal={showConsultingAgreement}
            />
        </>
    );
};

RegisterForm.propTypes = {
    image: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
