import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation } from "react-query";
import Masthead from '../../layout/masthead';
import {registerLoginDetails} from "../../../api/register";
import useInvitationCode from "../../../hooks/useInvitationCode";

export const StartStep = ({ image, summary, title, onFormSubmit }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm()

    const [invitationCode, checkInvitationCodeQuery] = useInvitationCode();

    const { data: invitationData } = checkInvitationCodeQuery;

    const registerLoginDetailsMutation = useMutation({
        mutationKey: ['registerLoginDetails', invitationCode],
        mutationFn: async (data) => {
            return await registerLoginDetails(data);
        },
        onSuccess: (data) => {
            onFormSubmit(data);
        },
        onError: (error) => {
          const formErrors = error.response?.form_errors;

          if (formErrors && typeof formErrors === 'object') {
                Object.keys(formErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: formErrors[key][0],
                    });
                });
            }
        }
    });

    const onSubmit = (data) => {
        registerLoginDetailsMutation.mutate(data);
    };

    if (checkInvitationCodeQuery.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <form
            className='c-form'
            onSubmit={handleSubmit(onSubmit)}
        >
            <Masthead title={title} summary={summary}/>
            <div className='c-register'>
                <div className='container'>
                    <div className='row'>
                        <div className='c-register__media-container'>
                            <img
                                src={
                                    image !== null
                                        ? image
                                        : '/images/test/contact.png'
                                }
                                alt=''
                                className='c-register__media'
                                loading='lazy'
                            />
                        </div>
                        <div className='c-register__form c-register__start-step'>
                            <div>
                                {(checkInvitationCodeQuery.isError || !invitationCode) ? (
                                    <div className='f-section'>
                                        <div className="f-messages">
                                            <div className="f-messages__alert f-messages__alert--error">
                                                <p>Invitation link is invalid or invitation has been accepted. Please login
                                                    instead. </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='c-masthead__subtitle'>
                                            Hi {invitationData?.first_name} {invitationData?.last_name}
                                        </div>
                                        <h2 className='c-content-media__title c-preregister__title'>
                                            Create your account
                                        </h2>
                                        <div className='c-form '>
                                            <input type='hidden' name='invitation_key'
                                                   {...register('invitation_key', {value: invitationCode})}
                                            />
                                            <div className='row'>
                                                <div className='f-field c-preregister__w-full'>
                                                    <div
                                                        className={classNames('f-field__elem')}
                                                    >
                                                        <input
                                                            name='email'
                                                            className='f-control'
                                                            type='text'
                                                            placeholder='Email address'
                                                            value={invitationData?.email}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='f-field c-preregister__w-full'>
                                                    <div
                                                        className={classNames(
                                                            'f-field__elem',
                                                            {
                                                                'f-field__elem--has-error':
                                                                errors.password,
                                                            }
                                                        )}
                                                    >
                                                        <input
                                                            name='password'
                                                            className='f-control'
                                                            placeholder='Password'
                                                            {...register('password', {
                                                                required:
                                                                    'This field is required',
                                                            })}
                                                            type='password'
                                                        />

                                                        {errors.password && (
                                                            <span className='f-error'>
                                                    {
                                                        errors.password
                                                            .message
                                                    }
                                                </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='c-register__start-step-hintBlock'>
                                            <div className='c-contact__form-field'>
                                                <p className='c-contact__form-consent'>
                                                    Choose a password, minimum 6
                                                    characters.
                                                </p>
                                            </div>
                                            <div className='c-preregister__form-footer'>
                                                <button
                                                    type='submit'
                                                    className='c-btn c-btn--large c-btn--primary c-preregister__submit'
                                                >
                                                    LET'S GO
                                                </button>
                                                {errors.__all__ &&
                                                    <span className='f-error'>
                                                        {errors.__all__.message}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className='c-content-media__content c-register__hint'>
                                            Be prepared, we’ll need to ask for a photo
                                            ID to verify your identity before you start
                                            earning fees.
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

StartStep.propTypes = {
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,

};
