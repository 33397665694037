import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from "react-query";
import PropTypes from 'prop-types';
import { isEmail } from '../../../helpers';
import classNames from 'classnames';
import {verifyUniversity} from "../../../api/register";

export const UniversityAffilationForm = ({ onSubmit }) => {
    const {
        handleSubmit,
        formState,
        register,
        setError,
    } = useForm({
        defaultValues: { university_email: '' },
    });

    const verifyUniversityMutation = useMutation({
        mutationKey: 'verifyUniversity',
        mutationFn: verifyUniversity,
        onSuccess: (data) => {
            onSubmit(data);
        },
        onError: (error) => {
            const formErrors = error.response?.form_errors;

            if (formErrors && typeof formErrors === 'object') {
                Object.keys(formErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: formErrors[key][0],
                    });
                });
            } else {
                setError('non_field_errors', {
                    type: 'manual',
                    message: 'Server Error - Please try again later',
                });
            }
        }
    });

    return (
        <form
            onSubmit={handleSubmit(verifyUniversityMutation.mutate)}
            className='c-register__identityForm'
        >
            <div className='f-field'>
                <div
                    className={classNames('f-field__elem', {
                        'f-field__elem--has-error': formState.errors.university_email,
                    })}
                >
                    <input
                        className='f-control'
                        {...register('university_email', {
                            validate: (v) => {
                                if (!v) return 'This field is required';

                                if (!isEmail(v)) {
                                    return 'Email is invalid';
                                }
                                return true;
                            },
                        })}
                        type='text'
                        placeholder='University email address'
                    />
                    {formState.errors.university_email && (
                        <span className='f-error'>
                            {formState.errors.university_email.message}
                        </span>
                    )}
                </div>
            </div>

            <div>
                <button
                    type='submit'
                    className='c-contact__submit c-btn c-btn--large c-btn--primary'
                >
                    Send
                </button>
            </div>

            {formState.errors.__all__ &&
                <span className='f-error'>
                    {formState.errors.__all__.message}
                </span>
            }

            {formState.errors.non_field_errors &&
                <span className='f-error'>
                    {formState.errors.non_field_errors.message}
                </span>
            }

            {verifyUniversityMutation.isSuccess &&
                <div>
                    Please now check your inbox for an email from Join
                    Associates and click the link within the email.
                </div>
            }
        </form>
    );
};

UniversityAffilationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
