import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import classNames from 'classnames';
import {uploadDocument} from "../../../api/register";

export const IdentityVerificationForm = ({ onSubmit }) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        setError,
    } = useForm({
        defaultValues: { identity_upload: '' },
    });

    const onUpload = (e) => {
        const files = e.target.files;
        setValue('identity_upload', files ? files[0] : '');
        e.target.value = null;
    };

    const uploadIdentityMutation = useMutation({
        mutationKey: 'uploadIdentity',
        mutationFn: uploadDocument,
        onSuccess: (data) => {
            onSubmit(data);
        },
        onError: (error) => {
            const formErrors = error.response?.form_errors;

            if (formErrors && typeof formErrors === 'object') {
                Object.keys(formErrors).forEach((key) => {
                    setError(key, {
                        type: 'manual',
                        message: formErrors[key][0],
                    });
                });
            } else {
                setError('non_field_errors', {
                    type: 'manual',
                    message: 'Server Error - Please try again later',
                });
            }
        }
    });

    return (
        <form
            onSubmit={handleSubmit(uploadIdentityMutation.mutate)}
            className='c-register__identityForm'
        >
            <div
                className={classNames('f-field__elem', {
                    'f-field__elem--has-error': errors.identity_upload,
                })}
            >
                <Controller
                    control={control}
                    name={'identity_upload'}
                    rules={{
                        validate: (v) => {
                            if (!v)
                                return 'This field is required';

                            return true;
                        },
                    }}
                    render={({ field: { value, ...field } }) => {
                        return (
                            <label className='f-control c-preregister__fileLabel'>
                                <input
                                    {...field}
                                    className='c-preregister__fileInput'
                                    type='file'
                                    onChange={onUpload}
                                    style={{
                                        bagroundColor: 'red',
                                        width: 100,
                                        height: 100,
                                    }}
                                />
                                <span className='c-preregister__fileName'>
                                    <span className='c-preregister__fileValue'>
                                        {value ? value.name : 'Upload ID'}
                                    </span>
                                </span>
                                <div className='c-preregister__fileLabel-btn'>
                                    BROWSE
                                </div>
                            </label>
                        );
                    }}
                />

                {errors.identity_upload && (
                    <span className='f-error'>{errors.identity_upload.message}</span>
                )}
            </div>
            <p className='c-contact__form-consent'>
                Please make sure your file is in one of these formats: PDF, JPG,
                PNG
            </p>
            <div>
                <button
                    type='submit'
                    className='c-contact__submit c-btn c-btn--large c-btn--primary'
                >
                    Upload
                </button>
            </div>
            {errors.__all__ &&
                <span className='f-error'>
                    {errors.__all__.message}
                </span>
            }

            {errors.non_field_errors &&
                <span className='f-error'>
                    {errors.non_field_errors.message}
                </span>
            }
        </form>
    );
};

IdentityVerificationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
