import React from 'react';
import { useMutation } from "react-query";
import { StepsIndicator } from '../preregister/steps-indicator';
import InternationalWorkingPolicyContent from '../../modals/InternationalWorkingPolicyContent';
import { agreeIWP as agreeIWPAPI } from '../../../api/register';

export const InternationalWorkingPolicyPage = ({
    onClick,
    step,
    stepsLength,
}) => {
    const agreeIWP = useMutation({
        mutationKey: "agreeIWP",
        mutationFn: agreeIWPAPI,
        onSuccess: () => {
            onClick();
        },
    });


    return (
        <div className='c-preregister__step-wrapper c-preregister c-preregister--step ReactModal__Content'>
            <div className='c-register c-preregister__step-wrapper__content'>
                <div className='container c-preregister__step-wrapper__content'>
                    <div className='c-register__internationalWorkingPolicyPage'>
                        <div className='row'>
                            <div className='c-testimonial-carousel__content-container c-preregister__testimonial'></div>
                            <div className='c-register__form c-preregister__form'>
                                <StepsIndicator
                                    currentStep={step}
                                    length={stepsLength}
                                />
                            </div>
                        </div>
                        <div className='c-preregister__step-wrapper__grow c-register__internationalWorkingPolicyPage-content'>
                            <InternationalWorkingPolicyContent />

                            {/* <p>
                                Contact us on{' '}
                                <a href='mailto:join.associates@outsmartinsight.com'>
                                    join.associates@outsmartinsight.com
                                </a>{' '}
                                and we will help you to configure your profile.
                            </p> */}

                            <button
                                type='button'
                                className='c-btn c-btn--large c-btn--primary'
                                onClick={() => agreeIWP.mutate()}
                            >
                                Got it
                            </button>
                            {agreeIWP.isError && (
                                <span className='c-form-error'>
                                    Server error. Please try again later.
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
