/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { siteMetadata } from '../../site-config';

export default function InternationalWorkingPolicyContent() {
    return (
        <>
            <h2>International Working Policy</h2>

            <p>
                Our Associates hold a variety of nationalities and undertake paid consultancy work from many locations around the world. Each individual Associate may rely on different working and immigration permissions to provide their services as an independent contractor to Outsmart Insight, and must therefore ensure they comply with all relevant tax and immigration laws applicable to their current location, home country and the permitted scope of their visa.
            </p>

            <p>
                <strong>Non-UK nationals living/working in the UK</strong>
                <br />
                UK immigration rules are changing and place restrictions on who is eligible to work on a self-employed or consulting basis whilst in the UK. Your location may therefore determine when you can participate in projects.
            </p>

            <p>
                Non-UK Nationals currently living and intending to work in the UK must have a valid work visa, with the exception of Irish nationals or EU/EEA/Swiss nationals with pre-settled or settled status. Your visa will state if there are restrictions on the kind of work you can do, including supplementary freelance or consulting work that can be classed as self-employment.
            </p>

            <p>For clarity, nationals holding a Skilled Worker (formerly Tier 2) or Student (formerly Tier 4) visa are not permitted under current UK immigration rules to take part in most types of work that can be classed as self-employment. You may only participate in consultancy work when you are outside the UK, subject to any immigration laws applicable at your overseas location.</p>

            <p>
                Nationals holding a Graduate, Global Talent, or Youth Mobility Scheme visa or with Indefinite Leave to Remain are eligible to participate in most kinds of consultancy work from locations both within or outside the UK, subject to any immigration laws applicable at your overseas location.
            </p>

            <p>
                Contact us on <a href={`mailto:${siteMetadata.email.contact}`} target={"_blank"} rel={"nofollow"}>{siteMetadata.email.contact}</a> and we will help you to configure your profile.
            </p>
        </>
    );
}
