import React from 'react';
import { ControlledDropdown } from './dropdown/controlled-dropdown';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

export const DatePicker = ({
    value,
    maxDate,
    minDate,
    onChange,
    placeholder,
    defaultView = 'decade',
}) => {
    const [isOpened, setIsOpened] = React.useState(false);
    return (
        <ControlledDropdown
            trigger={
                <div
                    className={classNames('c-date-picker', {
                        'c-date-picker--noValue': !value,
                    })}
                >
                    {value ? format(value, 'MMM yyyy') : placeholder}
                </div>
            }
            isOpened={isOpened}
            setIsOpened={setIsOpened}
        >
            <Calendar
                defaultView={defaultView}
                maxDate={maxDate}
                minDate={minDate}
                value={value}
                onChange={onChange}
                onClickDay={() => setIsOpened(false)}
                onClickMonth={(d) => {onChange(d); setIsOpened(false);}}
            />
        </ControlledDropdown>
    );
};

DatePicker.propTypes = {
    value: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
};
