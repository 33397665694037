/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Modal  from 'react-modal';

Modal.setAppElement('body');

export default function PrivacyPolicy({ showModal, onHide }) {
  return (
    <Modal isOpen={showModal} onRequestClose={onHide}>
      <button type="button" className="btn-close" onClick={onHide}>
        <span aria-hidden="true">&times;</span>
      </button>

      <h2>Privacy Policy</h2>
      <h3>Introduction</h3>
      <p>
        Outsmart Insights Limited is committed to protecting the privacy and
        security of your personal information. This privacy notice describes
        how we collect and use personal information about you during and after
        your relationship with us, in accordance with the applicable data
        protection legislation.
      </p>
      <p>
        As you will see in the Policy, we only use the information collected
        to improve our services for our users. If after reviewing the Policy
        you have any further questions, please contact us at{' '}
        <a href="mailto:messages@outsmartinsight.com">
          messages@outsmartinsight.com
        </a>
        .
      </p>

      <p>
        Outsmart Insights Limited is a "data controller". This means that we
        are responsible for deciding how we hold and use personal information
        about you. Under data protection legislation we are required to notify
        you of the information contained in this privacy notice.
      </p>

      <p>
        This notice applies to outsmartinsight.com and all websites that we
        operate and all services we provide (“our websites and services”).
      </p>
      <p>
        When we say ‘personal data’ we mean identifiable information about
        you, like your name, date of birth, nationality, email, address,
        telephone number, IP address, address, education, professional
        experience, curriculum vitae, support queries, community comments and
        so on. Special categories of personal data specifically include
        passport or National ID are processed to uniquely identify an
        individual as referred as ‘personal sensitive data’.
      </p>
      <p>
        If you can’t be identified (for example, when personal data has been
        aggregated and anonymised) then this notice doesn’t apply.
      </p>
      <p>
        We may need to update this notice from time to time. Where a change is
        significant, we’ll make sure we let you know – usually by sending you
        an email.
      </p>
      <p>
        It is important that you read this notice, together with any other
        privacy notice we may provide on specific occasions when we are
        collecting or processing personal information about you, so that you
        are aware of how and why we are using such information.
      </p>

      <p>
        Last updated: <strong>24 May 2018</strong>
      </p>

      <p>
        Here is a summary of some of the key changes we made on 24 May 2018:
      </p>

      <p>
        <strong>Navigation:</strong> We split the privacy notice into clear
        and defined sections so that it’s more user-friendly and easier to
        navigate to the information you need.
      </p>

      <p>
        <strong>Outsmart Insight:</strong> We clarified who Outsmart Insight
        is, and where we are headquartered.
      </p>

      <p>
        <strong>Principles:</strong> We complied with data protection law.
        This says that the personal information we hold about you must be kept
        secure, kept only as long as is necessary, is accurate and uptodate,
        is relevant to the purposes we have told you about and limited only to
        those purposes, collected only for valid purposes that we have clearly
        explained and used lawfully, fairly and in a transparent way.
      </p>

      <p>
        <strong>Collection and Use:</strong> We provided more information
        about the ways we collect personal data about you, who we collect that
        data from, and how we intend to use it.
      </p>

      <p>
        <strong>Sharing:</strong> We are more prescriptive about who we share
        your personal data with, which includes third party service providers
        and partners, regulators, an actual or potential buyer or where
        required by law.
      </p>

      <p>
        <strong>Security:</strong> We restated our commitment to protecting
        your personal data using appropriate technical and organisational
        measures.
      </p>

      <p>
        <strong>Data Subject Rights:</strong> We set out in more detail all
        rights individuals have in relation to their personal data and how
        those rights can be exercised.
      </p>

      <h3>Who are ‘we’?</h3>
      <p>
        When we refer to ‘we’ (or ‘our’ or ‘us’), that means Outsmart Insight
        Limited and all its wholly owned subsidiaries. Our headquarter is in
        London, UK. Address details for our offices are available on our{' '}
        <a
          href="https://www.outsmartinsight.com/"
          target="_blank"
          rel="noreferrer"
          title="Corporate page"
        >
          website
        </a>
        .
      </p>

      <h3>Our principles of data protection</h3>
      <p>
        Our approach to data protection is built around four key principles.
        They’re at the heart of everything we do relating to personal data.
        The personal information we hold about you must be
      </p>

      <ul>
        <li>Used lawfully, fairly and in a transparent way.</li>
        <li>
          Collected only for valid purposes that we have clearly explained to
          you and not used in any way that is incompatible with those
          purposes.
        </li>
        <li>
          Relevant to the purposes we have told you about and limited only to
          those purposes.
        </li>
        <li>Accurate and kept up to date.</li>
        <li>
          Kept only as long as necessary for the purposes we have told you
          about.
        </li>
        <li>Kept securely</li>
      </ul>

      <h3>How we collect your personal data</h3>
      <p>
        When you visit our websites or use our services, we collect personal
        data. The ways we collect it can be broadly categorised into the
        following:
      </p>

      <p>
        <strong>Information you provide to us directly:</strong> When you
        visit or use some parts of our websites and/or services we might ask
        you to provide personal data to us. For example, we ask for your
        contact information when you pre-register interest, respond to a job
        application or an email offer, participate in community forums, join
        us on social media, take part in training and events, contact us with
        questions or request support, and subscribe to our newsletter. If you
        don’t want to provide us with personal data, you don’t have to, but it
        might mean you can’t use some parts of our websites or services.
      </p>

      <p>
        <strong>Information we collect automatically:</strong> We collect some
        information about you automatically when you visit our websites or use
        our services, like your IP address and device type. We also collect
        information when you navigate through our websites, including what
        pages you looked at and what links you clicked on. This information is
        useful for us as it helps us get a better understanding of how you’re
        using our websites and services so that we can continue to provide the
        best experience possible (for example, by personalising the content
        you see).
      </p>

      <p>
        When you use our websites we and our Processors may use “cookies”,
        “web beacons”, and similar devices to track your activities. These
        small pieces of information are stored on your hard drive, not on our
        websites.
      </p>

      <p>
        We use cookies to help you navigate our websites as easily as
        possible, and to remember information about your current session. We
        do not use this technology to spy on you or otherwise invade your
        privacy. You can disable cookies and tracking technologies through
        your web browser, however doing so may render some aspects of our
        websites unusable.
      </p>

      <p>
        <strong>Information we get from third parties:</strong> The majority
        of information we collect, we collect directly from you. Sometimes we
        might collect personal data about you from other sources, such as
        publicly available materials or trusted third parties like LinkedIn or
        other publicly available profiles. We use this information to
        supplement the personal data we hold about you, in order to better
        match you to the right consulting opportunities, inform, personalise
        and improve our services, and to validate the personal data you
        provide.
      </p>

      <p>Where we collect personal data, we’ll only process it:</p>

      <ul>
        <li>to perform a contract with you, or</li>
        <li>
          where we have legitimate interests to process the personal data and
          they’re not overridden by your rights, or
        </li>
        <li>in accordance with a legal obligation, or</li>
        <li>where we have your consent.</li>
      </ul>

      <p>
        If we don’t collect your personal data, we may be unable to provide
        you with all our services, and some functions and features on our
        websites may not be available to you.
      </p>

      <p>
        If you’re someone who doesn’t have a relationship with us, but believe
        that an Outsmart Insight Representative or Consultant has entered your
        personal data into our websites or services, you’ll need to contact
        that Representative or Consultant for any questions you have about
        your personal data (including where you want to access, correct,
        amend, or request that the user delete, your personal data).
      </p>

      <h3>How we use your personal data</h3>
      <p>
        First and foremost, we use your personal data to operate our websites
        and:
      </p>

      <ul>
        <li>verify your identity in order to comply with UK Law</li>
        <li>checking you are legally entitled to work in the UK</li>
        <li>assessing qualifications for a particular task or activity</li>
        <li>receiving your work</li>
        <li>paying you</li>
        <li>administering the contract, we have entered into with you</li>
        <li>complying with health and safety obligations</li>
        <li>to prevent fraud</li>
      </ul>

      <p>
        We also use your personal data for other purposes, which may include
        the following:
      </p>

      <p>
        <strong>To communicate with you.</strong> This may include:
      </p>
      <ul>
        <li>
          providing you with information you’ve requested from us or
          information we are required to send to you
        </li>
        <li>
          operational communications, like changes to our websites and
          services, security updates, or assistance with using our websites
          and services
        </li>
        <li>
          communications another product or service we think you might be
          interested in, in accordance with your communication preferences
        </li>
        <li>
          asking you for feedback or to take part in research we are
          conducting (which we may engage a third party to assist with).
        </li>
      </ul>

      <p>
        <strong>To support you:</strong> This may include assisting with the
        resolution of technical support issues or other issues relating to our
        websites or services, whether by email, in-app support or otherwise.
      </p>

      <p>
        <strong>To protect you:</strong> So that we can detect and prevent any
        fraudulent or malicious activity, and make sure that everyone is using
        the our websites and services fairly and in accordance with Consulting
        Agreement.
      </p>

      <p>
        <strong>
          To enhance our websites and services and develop new ones:
        </strong>{' '}
        For example, by tracking and monitoring your use of our websites and
        services so we can keep improving, or by carrying out technical
        analysis of our websites and services so that we can optimise your
        user experience and provide you with more efficient tools.
      </p>

      <p>
        <strong>To analyse, aggregate and report:</strong> We may use the
        personal data we collect about you and other users of our websites and
        services (whether obtained directly or from third parties) to produce
        aggregated and anonymised analytics and reports, which we may share
        publicly or with third parties.
      </p>

      <h3>How we collect your personal sensitive data</h3>
      <p>
        "Special categories" of particularly sensitive personal information
        require higher levels of protection. We need to have further
        justification for collecting, storing and using this type of personal
        information. We may process special categories of personal information
        in the following circumstances:
      </p>
      <ul>
        <li>in limited circumstances, with your explicit written consent</li>
        <li>
          where we need to carry out our legal obligations in line with UK law
          and other international legal jurisdictions
        </li>
      </ul>

      <p>
        Less commonly, we may process this type of information where it is
        needed in relation to legal claims or where it is needed to protect
        your interests (or someone else's interests) and you are not capable
        of giving your consent, or where you have already made the information
        public.
      </p>

      <h3>How we use your personal sensitive data</h3>

      <p>
        We will use your particularly sensitive personal information in the
        following ways:
      </p>

      <ul>
        <li>
          checking you are legally entitled to work in the UK or other
          international jurisdictions
        </li>
        <li>allowing you permission to access high security sites</li>
      </ul>

      <p>
        We do not need your consent if we use special categories of your
        personal information in accordance with our written policy to carry
        out our legal obligations. In limited circumstances, we may approach
        you for your written consent to allow us to process certain
        particularly sensitive data. If we do so, we will provide you with
        full details of the information that we would like and the reason we
        need it, so that you can carefully consider whether you wish to
        consent. You should be aware that it is not a condition of your
        contract with us that you agree to any request for consent from us.
      </p>

      <h3>How we can share your data</h3>
      <p>
        There will be times when we need to share your personal data with
        third parties, including third-party service providers and other
        entities in the group. We require third parties to respect the
        security of your data and to treat it in accordance with the law. We
        may transfer your personal information outside the EU. If we do, you
        can expect a similar degree of protection in respect of your personal
        information. We will only disclose your personal data to:
      </p>

      <ul>
        <li>
          third party service providers and partners who assist and enable us
          to use the personal data to, for example, support delivery of or
          provide functionality on our websites or services
        </li>
        <li>
          regulators, law enforcement bodies, government agencies, courts or
          other third parties where we think it’s necessary to comply with
          applicable laws or regulations, or to exercise, establish or defend
          our legal rights. Where possible and appropriate, we will notify you
          of this type of disclosure
        </li>
        <li>
          an actual or potential buyer (and its agents and advisers) in
          connection with an actual or proposed purchase, merger or
          acquisition of any part of our business
        </li>
        <li>other people where we have your consent.</li>
      </ul>

      <h3>Security</h3>
      <p>
        Security is a priority for us when it comes to your personal data.
        We’re committed to protecting your personal data and have appropriate
        technical and organisational measures in place to make sure that
        happens. We have put in place appropriate security measures to prevent
        your personal information from being accidentally lost, used or
        accessed in an unauthorised way, altered or disclosed.
      </p>
      <p>
        In addition, we limit access to your personal information to those
        employees, agents, clients and third parties who have a business need
        to know. They will only process your personal information on our
        instructions and they are subject to a duty of confidentiality.
      </p>

      <p>
        We have put in place procedures to deal with any suspected data
        security breach and will notify you and any applicable regulator of a
        suspected breach where we are legally required to do so.
      </p>

      <h3>Retention</h3>
      <p>
        The length of time we keep your personal data depends on what it is
        and whether we have an ongoing business need to retain it (for
        example, your request to provide us with your services or to comply
        with applicable legal, tax or accounting requirements).
      </p>
      <p>
        We will retain your personal data for as long as we have a
        relationship with you and for a period of time afterwards where we
        have an ongoing business need to retain it. Following that period,
        we’ll make sure it’s deleted or anonymised.
      </p>

      <h3>Your rights</h3>
      <p>
        It’s your personal data and you have certain rights relating to it.
        When it comes to marketing communications, you can ask us not to send
        you these at any time – just follow the unsubscribe instructions
        contained in the marketing communication, or send your request to{' '}
        <a href="mailto:messages@outsmartinsight.com">
          messages@outsmartinsight.com
        </a>
        .
      </p>

      <p>You also have rights to:</p>
      <ul>
        <li>
          know what personal data we hold about you, and to make sure it’s
          correct and up to date
        </li>
        <li>
          request a copy of your personal data, or ask us to restrict
          processing your personal data or delete it
        </li>
        <li>object to our continued processing of your personal data</li>
      </ul>

      <p>
        You can exercise these rights at any time by sending an email to{' '}
        <a href="mailto:messages@outsmartinsight.com">
          messages@outsmartinsight.com
        </a>
        .
      </p>
      <p>
        If you’re not happy with how we are processing your personal data,
        please let us know by sending an email to{' '}
        <a href="mailto:messages@outsmartinsight.com">
          messages@outsmartinsight.com
        </a>
        . We will review and investigate your complaint, and try to get back
        to you within a reasonable time frame. You can also complain to your
        local data protection authority. They will be able to advise you how
        to submit a complaint.
      </p>

      <h3>How to contact us</h3>
      <p>
        We’re always keen to hear from you. If you’re curious about what
        personal data we hold about you or you have a question or feedback for
        us on this notice or our websites or services, please get in touch.
      </p>
      <p>
        As a technology company, we prefer to communicate with you by email –
        this ensures that you’re put in contact with the right person, in the
        right location, and in accordance with any regulatory time frames.
      </p>
      <p>
        Our email is{' '}
        <a href="mailto:messages@outsmartinsight.com">
          messages@outsmartinsight.com
        </a>
        .
      </p>
      <button
        type="button"
        className="c-btn c-btn--large c-btn--primary"
        onClick={onHide}
      >
        Close
      </button>
    </Modal>
  );
}

PrivacyPolicy.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
