import React from 'react';
import PropTypes from 'prop-types';
import { ControlledCollapse } from './controlled-collapse';

export const Collapse = (props) => {
    const [isOpened, setIsOpened] = React.useState(false);

    return (
        <ControlledCollapse
            {...props}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
        />
    );
};

Collapse.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
