import classNames from 'classnames';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '../../date-picker';
import { startOfDay, addDays } from 'date-fns';
import { SelectField } from '../../fields/select-field';
import { DEEGREE_CHOICES } from '../../../constants';

export const AlumFields = () => {
    const {
        formState: { errors },
        register,
        control,
        setValue,
    } = useFormContext();
    const minEndDate = startOfDay(addDays(new Date(), 1));

    return (
        <>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_level,
                        })}
                    >
                        <Controller
                            control={control}
                            name='degree_level'
                            render={({ field }) => {
                                return (
                                    <SelectField
                                        {...field}
                                        data={DEEGREE_CHOICES}
                                        placeholder='Degree level'
                                    />
                                );
                            }}
                        />
                        {errors.degree_level && (
                            <span className='f-error'>
                                {errors.degree_level.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field c-preregister__w-full'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_field,
                        })}
                    >
                        <input
                            name='degree_field'
                            className='f-control'
                            type='text'
                            placeholder='Field of study/research
                '
                            {...register('degree_field', {
                                required: 'This field is required',
                            })}
                        />
                        {errors.degree_field && (
                            <span className='f-error'>
                                {errors.degree_field.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='f-field'>
                    <div
                        className={classNames('f-field__elem', {
                            'f-field__elem--has-error': errors.degree_end,
                        })}
                    >
                        <Controller
                            name='degree_end'
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <div className='c-register__datePickerPlaceholder'>
                                    <DatePicker
                                        onChange={(v) => {
                                            setValue('degree_end', v);
                                        }}
                                        value={field.value}
                                        maxDate={minEndDate}
                                        placeholder='Completion date'
                                    />
                                </div>
                            )}
                        />
                        {errors.degree_end && (
                            <span className='f-error'>
                                {errors.degree_end.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
